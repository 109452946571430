import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-emptystates',
  templateUrl: './emptystates.component.html',
  styleUrls: ['./emptystates.component.scss']
})
export class EmptystatesComponent implements OnInit {

  @Input() message:string;

  constructor() { }

  ngOnInit(): void {
  }


}
