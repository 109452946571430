import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successmail',
  templateUrl: './successmail.component.html',
  styleUrls: ['./successmail.component.scss']
})
export class SuccessmailComponent implements OnInit {
  public loading = true;
  public verified ;

  constructor(private service: AuthServiceService, private actRoute: ActivatedRoute, private cookieService: CookieService) { }

  ngOnInit(): void {
    
    this.service.headerColor.next(true)
    const id = this.actRoute.snapshot.paramMap.get('id');
    this.service.emailverify(id).subscribe((data: any) => {
     this.loading = false;
     this.verified = true;
      console.log(data);
      
      this.cookieService.set('temp', data.token, 1, '/');
      this.cookieService.set('register', 'true', 1, '/');     

      }, err => { 
        this.loading = false;
        this.verified = false;
       })
    
  }

}
