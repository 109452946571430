import { AuthServiceService } from './../service/auth/auth-service.service';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthServiceService,private router: Router){}
  canActivate() {
  
    if(this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard/browseprojects'])
      return false
    }

    
    console.log('auth log false');
    return true;

    }
    
  }
  

