import {  AuthServiceService } from '../auth/auth-service.service';

import { Injectable,Injector } from '@angular/core';
import { HttpEvent,HttpHandler,HttpInterceptor,HttpRequest, HttpErrorResponse } from "@angular/common/http";
import {  Observable,throwError} from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    constructor(private injector : Injector, private router: Router, private cookie: CookieService){}

    intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>> {
        console.log(req.url);
        let authservice = this.injector.get(AuthServiceService)
        if(authservice.getToken()) {
            req = req.clone({
                setHeaders: { Authorization: `Bearer ${authservice.getToken()}` }
            })
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) =>{
                // alert(`Something went wrong.`);
               if(error.status < 200){
                   if( error.error ) {
                      alert(error.error);
                   } else {
                    alert(`Informational response Error`);
                   }
               }
               if(error.status < 400){
                if( error.error ) {
                    console.log(error);                   
                   alert(error.error.error);
                } else {
                 alert(`Client errors `);
                }
              }
              if(error.status < 600){
                if(error.status == 404) {
                  this.cookie.deleteAll('/')
                   this.router.navigate(['/onboard/login'])
                }
                if( error.error ) {
                    if(error.error.error == 'Stripe verification not complete!') {
                      console.log(error.error.error);
                      authservice.getPaymentURL().subscribe( (data: any) => {
                        window.open(data.url.url, "_self")
                        console.log(data);
                      })
                      
                    }   else {
                           alert(error.error.error);
                    }
                
                } else {
                 alert(` Server errors`);
                }
              }
                
            return throwError(error)
            })
        )
    }
}