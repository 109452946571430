import { Component, DoCheck, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Socket } from 'ngx-socket-io';
import { AuthServiceService } from './service/auth/auth-service.service';
import { SocketoneService, SockettwoService } from './service/socketconnection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public navigationLinks;
  public username;
  public imageurl;
  public isHeader;
  
  constructor( private sanitizer: DomSanitizer, private authService: AuthServiceService,private cookie: CookieService,private socket: SocketoneService, private socket2: SockettwoService) { }

  ngOnInit(){
    if(this.authService.isLoggedIn()) {
      this.authService.showHeader.next(true)
    } else {
      this.authService.showHeader.next(false)

    }





    this.socket.on('engineerJoin',(data)=>{
      this.socket.emit('joinSingleProject',{'projectID':data.projectID,'owner':'engineer'})
    })
    
    if (this.cookie.check('token')) {
      console.log(`jwt ${this.cookie.get('token')}`);
      this.socket.emit('joinRoom',{'token': this.cookie.get('token'),'owner': 'engineer' })
      this.socket.on('isReconnected', data => {
        console.log(data);
        this.socket.emit('tryReconnect', {'token': this.cookie.get('token'),'owner': 'engineer' })    
      })
      this.socket2.on('isReconnected', data => {
        console.log(data);
        this.socket2.emit('tryReconnect', {'token': this.cookie.get('token'),'owner': 'engineer'})    
      })
      this.getProfileData()
      
    }
    else {
      console.log('no token');
    }

  }
  getProfileData() {
    this.authService.viewprofile().subscribe((data: any) => {

      if(data.profile_verification_complete == false) {
        this.authService.getPaymentURL().subscribe((data: any) => {
          window.open(data.url.url, "_self")
          console.log(data);
          // this.getProfileData()
        })
        return 
      } 
      console.log(data)
      this.username = data.profile.username;
      if (data.avatar) {
        let TYPED_ARRAY = new Uint8Array(data.avatar.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');

        let base64String = btoa(STRING_CHAR);
        this.imageurl = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        console.log(this.imageurl);
      }
      this.authService.profileData.next(
        {
          username: data.profile.username,
          avatar: this.imageurl,
          block: data.profile.block
        }
      )
    }, err => console.log(err.error.error))
  }
}
