import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { ProjectService } from './../../../service/project/project.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-activeprojects',
  templateUrl: './activeprojects.component.html',
  styleUrls: ['./activeprojects.component.scss']
})
export class ActiveprojectsComponent implements OnInit {


  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  public option = {
    types: ['(cities)'],
    componentRestrictions: { country: 'IN' }
  };
  public location;
  public loading = true;
  public value  = true;
  public projects;
  emptyprojects = "There are no projects mapped to you, yet!"

  constructor(private loadingBar: LoadingBarService, private projectService: ProjectService, private AuthService: AuthServiceService) { }

  ngOnInit(): void {
    this.AuthService.avatarData.next(true);
    this.AuthService.header.next(true);
    this.loadingBar.start(0)
    this.projectService.getActiveprojects().subscribe((res: any) => {
      this.loadingBar.stop()
      console.log(res)
      this.loading = false;
      this.value = false;
      this.projects = res
    },
    err => {
      this.loading = false;
      this.loadingBar.stop()

    })
  }
  public handleAddressChange(address: Address) {
    this.location = address.formatted_address;
    console.log(address)
  }
 

}
