import { Injectable } from '@angular/core';
import { HttpClient,HttpBackend, HttpParams, HttpHeaders  } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment.prod';


@Injectable({
  providedIn: 'root'
})


export class AuthServiceService {

  public profileData = new BehaviorSubject<any>({});
  public showHeader = new BehaviorSubject<boolean>(false)



  public avatarData = new BehaviorSubject<any>(false);
  public headerColor = new Subject<any>();
  public notificationID = new Subject<string>();
  public header = new Subject<boolean>();
  public API = environment.apiUrl;
  public newHttp;
  constructor(private http : HttpClient,private cookieService: CookieService, private handler: HttpBackend) { 
    this.newHttp = new HttpClient(this.handler);

  }

  signup(data){
    return this.http.post( this.API + '/signup', data );
  }

  emailverify(id){
    return this.http.put(this.API + '/confirm_mail/'+ id, {})
  }

  login(data){
    return this.http.post( this.API + '/login', data );
  }

  register(data){
    console.log(this.cookieService.get('temp'));
    
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + this.cookieService.get('temp')
      })
    };
    return this.http.post( this.API + '/profile', data,httpOptions);
  }

  logout(){
    return this.http.post( this.API + '/logout',{})
  }

  getToken(){
    return this.cookieService.get('token');
  }

  loggedIn(){
    return this.cookieService.check('token');
  }

  forgotPassword(data){
    return this.http.post(this.API + '/forgot/password', data)
  }
  resetPassword(id, data) {
    return this.http.patch(this.API + '/reset/password/' + id, data)
  }
  resendEmail(data){
    return this.http.patch(this.API + '/resend/mail', data)
  }

  viewprofile(){
    return this.http.get(this.API + '/profile/view')
  }
  
  sendKey(url, data, httpOption) {
    return this.newHttp.put(url, data, httpOption);
  }

  sendURL(data, httpOption) {
    return this.newHttp.put(this.API + '/profile', data, httpOption);
  }
  // sendURLprofile(data, httpOption) {
  //   return this.newHttp.patch(this.API + '/profile', data, httpOption);
  // }
  
  getAllRevision(id){
    return this.http.get(this.API + '/revision/' + id)
  }

  bidOnRevisiton(id ,data) {
    return this.http.post(this.API + '/revision/bid/' + id, data)
  }

  acceptRevision(id){
    return this.http.patch (this.API + '/revision/accept/' + id, {})
  }

  rebidRevision(id, data){
    return this.http.patch( this.API + '/revision/rebid/' + id, data)
  }

  uploadRevisionDocs(data, id){
    return this.http.post( this.API + '/revision/docs/' + id, data)
  }
  saveRevisionDocs( id, data,httpOptions){
    return this.http.patch( this.API + '/revision/docs/' + id, data,httpOptions)
  }


  engineerSubmitDocs(data, id){
    return this.http.post(this.API + '/docs/upload/' + id, data )
  }
  engineerSubmitDocKey(data, id,httpOptions){
    return this.http.patch(this.API + '/docs/upload/' + id, data , httpOptions)
  }

  updateprofile(data){
    return this.http.patch(this.API + '/profile/update', data)
  }

  getPaymentURL(){
    return this.http.get(this.API + '/accountlink')
  }

  isLoggedIn(){
    return !!this.cookieService.check('token')
  }
  isRegisterCheck(){
    return !!this.cookieService.check('register')
  }

  deleteDocs(data){
    return this.http.post(this.API + '/profile/delete', data)
  }

  clearNotifications() {
    return this.http.post( this.API + '/noti/allread', {})
  }

}
