<main>
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="columnimg right"></div>
    <img src="../../../assets/images/logo.png" alt="" style="padding: 21px 0 0 42px;cursor: pointer;" routerLink="/onboard/login">

    <div class="columntext ">

        <div class="col-md-6">
            <div class="  mb-4 h-md-250">
                <div class="text-box">



                    <h1 class="display-5" style="font-weight: 700;">Verification <span style="font-weight: 400;">Mail
                                Sent</span></h1>
                    <p class=" card-text mb-auto">We've sent an email <b>{{email}}</b>
                        <br>Click the confirmation link in that email to begin using <b>Walnut | Engineer</b></p>
                    <p class=" card-text mb-auto" style="margin-top: 40px;">If you did not receive the email, then <span class="termstext" (click) = "resendEmail()">click here to resend.</span></p>


                </div>

            </div>
        </div>

    </div>


</main>

<div class="modal fade" id="success" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"  aria-hidden="true">
    <div class="modal-dialog password-dialog" role="document">
      <div class="modal-content" style="padding: 10px;">
        <div class="modal-body" >
            <h5>Verification email re-sent</h5>
            <p class="mt-4" style="font-size: 14px;">We,ve sent an email to <b>{{email}}</b> Click the confirmation link in that email to begin using Walnut | Engineer.</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-footer apply" data-dismiss="modal" >Done</button>
        </div>
      </div>
    </div>
</div>
 <!-- loading modal -->
 <!-- <div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="loadingmodal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content loadingmodal">

            <div class="modal-body">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <span class="loadText">Loading please wait...</span>
            </div>

        </div>
    </div>
  </div> -->
