<main>
    <div class="columnimg right"></div>
    <div class="columntext ">
        <div class="col-md-6">
            <div class="  mb-4 h-md-250">
                <div class=" d-flex flex-column align-items-center">
                    <div style="text-align: center;">
                        <!-- <img src="assets/images/walnutlogo.svg" style="padding-bottom: 15px;"> -->
                        <div style="padding-bottom: 15px;">
                            <img src="../../../../assets/images/logo.png" alt="">

                        </div>
                    </div>
                    <form name="form" style="text-align: center;"  (keyup.enter)="f.form.valid && emailsave() " #f="ngForm" novalidate>
                        <h1 class="display-5" style="font-weight: 700">
                            Build your <span style="font-weight: 400">Career</span>            
                          </h1>
                        <p class=" card-text mb-auto">Create account to find your next big project</p>
                        <div class="form-group" style="margin-top: 40px; text-align: left;">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #171717">Email
                                ID</label>
                            <input type="email" autocomplete="off" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$" placeholder="type here"
                                style="padding-top: 25px; padding-bottom: 25px">
                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.pattern">Email must be a valid email address</div>
                            </div>
                        </div>

                        <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px; ">By signing up, I agree to the <span data-toggle="modal" data-target="#termsModal" class="termstext">Terms and
                                Conditions</span></p>
                        <button *ngIf="submit == true" type="button" [disabled] = "f.form.invalid" (click)="emailsave()" class="btn proHirebutton btn-block" style="margin-top: 15px;padding: 12px 0px 12px 0px;">Create Account</button>
                        <button *ngIf="loading == true" class="btn proHirebutton btn-block" style="margin-top: 15px;padding: 12px 0px 12px 0px;">
                                    <div class="spinner-border text-light" role="status">
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                </button>
                        <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px;text-align: center;font-size: 16px;"> Already have an account? <span class="termstext" routerLink="/onboard/login">Sign In.</span></p>
                    </form>
                </div>

            </div>
        </div>

    </div>


    <!--Terms and Conditions-->
    <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 700;">User Agreement</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <pre>
Terms and Conditions of Use
1. Acceptance of Terms
1.1 The Royalnut Company, Inc., a Delaware corporation, doing business as The Walnut Company (and
referred to herein as &quot;The Walnut Company,&quot; &quot;thewalnutco.com,&quot; &quot;Company,&quot; &quot;us&quot; &quot;we&quot; or &quot;our&quot;),
provides www.thewalnutco.com (the &quot;Site&quot;), an online platform where Clients can post projects and
solicit bids from Professional Engineers (“PEs”) (Clients/projects and PEs/bids collectively referred to as
&quot;Offerings&quot;), subject to compliance with the following Terms and Conditions of Use (&quot;Terms&quot;).
1.2 We reserve the right to change these Terms from time to time with or without notice. As a User
(Client or PE), you acknowledge and agree that it is your responsibility to periodically review this Site
and these Terms. Your continued use of this Site and Offerings after such modifications will constitute
acknowledgement and acceptance of the modified Terms.
1.3 Our &quot;Affiliates&quot; include our owners, licensees, assignees, subsidiaries, affiliated companies, officers,
directors, suppliers, partners, sponsors, advertisers, and all parties involved in creating, producing, and
providing this Site and its Offerings. Your &quot;Associates&quot; include your partners, directors, officers,
managers, equity holders, associates, agents, representatives, assignees, employees, contractors,
successors, and any other persons contractually bound by you or them. These Terms shall be binding
upon our Affiliates, your Associates, other related entities, and upon the principals, employees,
assignees, heirs, and/or successors-in-interest of each.
1.4 Our Offerings are not available to citizens or residents of any member state of the European Union
or the European Economic Activity, regardless; if you are a citizen or resident of any member state of
the European Union or the European Economic Area, you must discontinue use of the Site
immediately.
1.5 BY USING THIS SITE AND OFFERINGS ON THIS SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND
OUR PRIVACY POLICY, INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT WISH TO BE BOUND BY
THESE TERMS, PLEASE EXIT THE SITE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS SITE,
OFFERINGS AVAILABLE ON THIS SITE, OR THESE TERMS, IS TO CEASE USING THE SITE AND/OR THOSE
PARTICULAR OFFERINGS.
2. Offerings
2.1 Offerings. The Walnut Company provides Offerings on its Site, which is an online platform for
building contractors, architects, governmental entities, etc. (&quot;Clients&quot;) and licensed PEs of all disciplines
to find and contract with each other on projects ranging in size, scope and location (&quot;Transactions&quot;).
2.2 No Guarantee. The Walnut Company cannot promise or guarantee specific results from using the
Site or Offerings available on this Site. You agree that the Offerings available on this Site are provided
&quot;AS IS&quot; and that we assume no responsibility for the timeliness, deletion, mis-delivery, or failure to store
any User communications, material posted by you, or personalization settings.
2.3 Temporary Interruptions. We reserve the right to withdraw or amend this Site, and any service or
material we provide on the Site, in our sole discretion without notice. We will not be liable if for any
reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may
restrict access to some parts of the Site, or the entire Site, to users, including registered users.

3. Fee-Structures; Confidentiality of Leads; Non-Circumvention
3.1 Compensation. We have a tiered pricing model and collect a percentage of the contract amount on
a contingency basis at the completion of each successful Transaction—15% if the contract amount is
below $1,000 and 20% if it is above $1,000.
3.2 Use of Escrow Services. To ensure Clients, PEs, and the Company are fairly treated and compensated
under any Transactions, our Offerings require the use of a third-party escrow service to hold funds and
distribute any payments upon the completion of a Transaction. By entering a Transaction, you agree and
consent to the use of the escrow service, including sharing with the escrow service any information
related to the Transaction and compensating the escrow service. Further, you affirm that you agree to
the escrow service’s terms and conditions for use and agree to adhere to any requirements placed by
the escrow service.
3.3 Transaction Deadlines and Disputes. We encourage the Client to provide clear project information,
with a deadline for project completion stated in days from bid acceptance, and the PE to submit any
questions prior to bidding on a project and commencing engineering service. For every 24-hour period a
question remains pending and unanswered, the PE will get an extra day to complete the design. When
the design is submitted, the Client will have 7 days to request a design revision. The PE will then have 3
days to complete the revision, or to request a revision charge revision is outside the scope of the initial
project. If a dispute emerges regarding a revision request, revision charge request, or other conduct or
results during a Transaction, we ask that you first work in good faith with the other party to reach a
mutually agreeable resolution. If you cannot successfully do so, you may then ask our Customer Service
team for assistance via email to info@thewalnutco.com. When you submit an issue related to a
Transaction to our Customer Service, we may ask for documents and communications related to your
concern to reach a decision that is fair and amenable to each of the parties. You acknowledge that
decisions made by our Customer Service are final and are made at our sole decision (regardless of
outcome), agree to comply with any decision issued by our Customer Service, and consent to a relevant
escrow service performing its duties according to the decision issued by our Customer Service.
3.4 Confidentiality. It is expressly agreed that the identities, Offerings and communications of any PEs or
Clients disclosed on the Site shall constitute &quot;Confidential Information&quot; for a period of three years after
it is disclosed. You agree that you shall use the Confidential Information solely for the purpose of
arranging and completing Transactions and shall not disclose or permit access to Confidential
Information other than to the parties involved in the relevant Transaction, or to us or our Affiliates. You
shall safeguard the Confidential Information from unauthorized use, access, or disclosure using at least
the degree of care you use to protect your most sensitive information and no less than a reasonable
degree of care. You shall promptly notify us of any unauthorized use or disclosure of Confidential
Information and cooperate to prevent further use or disclosure.
3.5 Non-Circumvention. It is expressly agreed that, for a period of three years after an introduction is
made, you and your Associates shall not, without our written consent, directly or indirectly initiate,
solicit, negotiate, contract or enter into any business transactions, agreements or undertakings with any
such PE or Client identified or introduced through the use of our Offerings except as pertains to
Transactions facilitated through our Offerings and for which we receive compensation.
3.6 Obligation to Inform. You agree that you will inform us of your or any of your Associates’ violation of
any of the clauses or obligations set under this Section 3. You further agree that for the purposes of
calculating damages, statutes of limitations, or any other purpose, your violation of the obligation to

inform under this Clause 3.6 is to be considered ongoing until and including the date we are fully
informed of the facts and circumstances relating to any violation.
3.7 Penalties for Violation. In the event of a violation by you or any of your Associates of any clause
under this Section 3, we will be entitled to your payment to us of the full monetary amount of any
financial gain made by you or to your Associate resulting from or related to any transaction or event
during which such violation arose. Further, nothing in the foregoing is intended to limit our ability to
seek to injunctive relief, legal fees, and any other equitable relief for such violation to the fullest extent
as is available according to law.
4. Site Conduct, Posting Policies &amp; Third Party Websites
4.1 User-Created Content Guidelines. Your use of the Site is subject to all applicable laws and
regulations, and you are solely responsible for any comments or posts you leave on the Site, send to us,
or send to other users. By posting information or original material on the Site, by sending material to us
for review, or by otherwise using any communications service, message board or other interactive
service available on the Site, you agree that you will not post or send material, comments, messages,
links, code, information, or pictures that adhere to the following &quot;Content Standards&quot;:
i. are unlawful, threatening, abusive, harassing, defamatory, deceptive, inaccurate, fraudulent,
tortious, invasive of another&#39;s privacy, or include graphic descriptions of sexual or violent
content;
ii. victimize, harass, degrade, or intimidate an individual or group of individuals on the basis of
religion, gender, sexual orientation, race, ethnicity, age, or disability;
iii. infringe on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary
right of any party;
iv. consist of unsolicited advertising, junk or bulk email (also known as &quot;spam&quot;), chain letters, any
other form of unauthorized solicitation, or any form of lottery or gambling;
v. contain any form of malicious code, files, or programs that are designed or intended to disrupt,
damage, or limit the functionality of any software, hardware, or telecommunications equipment
or otherwise cause damage, or allow you to obtain unauthorized access to any data or other
information of any third party;
vi. breach the security of, compromise or otherwise allow access to secured, protected or
inaccessible areas of this Site, or attempt to gain access to other networks or servers via your
account on this Site;
vii. impersonate any person or entity, including any of our employees or representatives.
4.2 No Endorsement. The Walnut Company neither endorses nor assumes any liability for any materials
uploaded or submitted by Users on any part of the Site. Although we do not pre-screen, police, or
monitor materials or comments posted on the Site, we and our agents reserve the right to remove any
and all postings, in our sole discretion and without prior notice to you. The Walnut Company and its
agents are not responsible for any failure or delay in removing such postings. The Walnut Company is
not liable for any inaccurate or incorrect information provided by Users posting projects on the Site. We
do not verify that projects listed by Clients are actual projects, nor do we verify the accuracy of the
listings. We also do not specifically endorse the PEs utilizing the Site. It is the responsibility of the Clients
to verify the PE’s credentials and proper licensing requirements for their specific projects. We do not
take responsibility for the outcome of any projects listed on our Site. Disputes pertaining to the work

performed must be resolved directly between the Client and the PE. By using this Site and its offerings,
you agree to hold The Walnut Company harmless in any such dispute.
4.3 Limitations. This Site is to be used for project matching purposes only. Projects listed on or posted to
the Site by or on behalf of the Clients must be specific in nature and not used as general job openings.
The message board shall be used only for the purpose of communication between the Clients, and the
PE, and shall be limited to discussing the specific project and proposal for such project and shall not be
used for any other purpose.
4.4 Third-Party Sites and Information. This Site may redirect or link to other websites on the Internet, or
may otherwise include references to information, products or services made available by unaffiliated
third parties (including Escrow Services). While we make every effort to work with trusted, reputable
providers, from time to time such sites may contain information, material or policies that some may find
inappropriate or personally objectionable. You understand that we are not responsible for the accuracy,
completeness, decency, or legality of content hosted by third party websites, nor are we responsible for
errors or omissions in any references made on those websites. The inclusion of such a link or reference
is provided merely as a convenience and does not imply endorsement of, or association with the Site or
party by us, or any warranty of any kind, either express or implied. By accepting a redirect or link to
other websites, you agree to adhere to the terms and conditions and privacy policies of those websites.
4.5 Products and Promotions. From time to time, this Site may include information about products and
promotions offered by third parties. You may purchase products from or participate in promotions of
third parties whose promotions or products are listed on this Site. Any such correspondence or
promotions, including the delivery of and the payment for goods and services by those third parties, and
any other terms, conditions, warranties or representations associated therewith, are solely between you
and the third party. We assume no liability, obligation, or responsibility for any part of any such
purchase or promotion.
5. The Walnut Company&#39;s Intellectual Property
5.1 Content. For purposes of these Terms, &quot;content&quot; is defined as any information, communications,
published works, photos, video, graphics, music, sounds, or other material that is sent to or that can be
viewed by users on our Site and is owned by The Walnut Company, our Affiliates or licensors.
5.2 Ownership of Content. All contents of the Site are: Copyright © The Walnut Company. All rights
reserved. All content on the Site is subject to intellectual property rights, contractual or other
protection. The intellectual property rights are owned by us or our licensors. No content may be copied,
distributed, republished, uploaded, posted, or transmitted in any way except as provided expressly in
these Terms or with The Walnut Company&#39; prior express written consent. Any use of the content other
than as permitted by these Terms, or any other unauthorized use of the content may make you liable to
The Walnut Company for violation of intellectual property rights.
5.3 Trademarks. Trademarks or service marks of The Walnut Company include, but are not limited to,
The Walnut Company™; thewalnutco.com™; and The Walnut Company logo. All custom graphics, icons,
logos and service names are registered trademarks, common law trademarks or service marks of The
Walnut Company, or our Affiliates. All other trademarks or service marks are property of their respective
owners. Nothing in these Terms grants you any right to use any trademark, service mark, logo, and/or
the name of The Walnut Company, thewalnutco.com, our Affiliates, or our licensors.
5.4 Site Use. The Walnut Company grants you a limited, revocable, nonexclusive license to use the Site
solely for your own personal purposes only and not for republication, distribution, assignment,

sublicense, sale, commercial use, preparation of derivative works, or other use. You may not use any
content from the Site for commercial use. You agree not to copy the Site, reverse engineer, or break into
the Site, or use materials, products, or services in violation of any law. Any use of the Site or the content
or materials contained therein, other than as specifically authorized in these Terms, without the prior
written permission of The Walnut Company, is strictly prohibited and will terminate the license granted
herein. Unless explicitly stated herein, nothing in these Terms shall be construed as conferring to you,
whether by implication, estoppel or otherwise, any title or ownership of, or exclusive use-rights to, any
intellectual property or other right, and any goodwill associated therewith. The Walnut Company
reserves the right, without notice and in its sole discretion, to terminate your license to use the Site at
any time and to block or prevent your future access to, and use of, the Site.
5.5 No Warranty for Third-Party Infringement. Neither our Affiliates nor we warrant or represent that
your use of materials displayed on, or obtained through, this Site will not infringe the rights of third
parties.
6. Content You Create
6.1 User Content. Subject to our Privacy Policy, any communication or material that you transmit to this
Site or to us, whether by email or by other means, for any reason, will be treated as non-confidential
and non-proprietary user content (&quot;User Content&quot;). While you retain all rights to the User Content, you
grant us (including our employees and Affiliates), a non-exclusive, paid-up, perpetual, and worldwide
license to copy, distribute, display, publish, translate, adapt, modify, and otherwise use the User
Content for any purpose whatsoever, regardless of the form or medium in which it is used. You
represent and warrant that you own or control all rights in and to the User Content and have the right to
grant the license granted above to us and our affiliates and service providers, and each of their and our
respective licensees, successors, and assigns.
6.2 Your Intellectual Property Rights. We respect the intellectual property rights of others, and we ask
you to do the same. In instances where we are notified of alleged infringing User Content, a decision
may be made to remove access or disable access to such materials, in compliance with the safe harbor
provisions of the Digital Millennium Copyright Act, 17 U.S.C. § 512(c).
If you believe that you or someone else&#39;s copyright has been infringed upon by thewalnutco.com or
User Content provided on this Site, you (or the owner or rights holder, collectively, &quot;Rights Holder&quot;)
should send notification to us immediately. Prior to sending us notice, the Rights Holder may wish to
consult a lawyer to determine their rights and legal obligations under the DMCA and any other
applicable laws. Nothing here or anywhere on this Site is intended as a substitute for qualified legal
advice. To file a Notice of Infringing Material, we ask that the Rights Holder provide the following
information:
 i. Reasonably sufficient details about the nature of the copyrighted work in question, or, in the
case of multiple alleged infringements, a representative list of such works. This should include,
title(s), author(s), any U.S. Copyright Registration number(s), URL(s), etc.;
 ii. Reasonably sufficient details to enable us to identify and locate the material that is allegedly
infringing the Rights Holder&#39;s work(s) (for example, file name or URL of the page(s) that
contain(s) the material);
 iii. The Rights Holder&#39;s contact information so that we can contact them (including for example,
the Rights Holder&#39;s address, telephone number, and email address);

 iv. A statement that the Rights Holder has a good faith belief that the use of the material
identified above in (ii) is not authorized by the copyright owner, its agent or the law;
 v. A statement, under penalty of perjury, that the information in the notification is accurate and
that the Rights Holder is authorized to act on behalf of the copyright owner; and
 vi. The Rights Holder&#39;s electronic signature.
Notice may be sent to us at info@thewalnutco.com.
6.3 You also acknowledge and agree that upon receipt of a notice of a claim of copyright infringement,
we may temporarily or permanently remove the identified materials from our Site without liability to
you or any other party.
6.4 Confidentiality of Communications. As stated above, all communications sent by you to us will be
treated as non-confidential and non-proprietary (subject to our Privacy Policy). Please do not submit
confidential or proprietary information to us (including patentable ideas, new content suggestions, or
business proposals) unless we have mutually agreed in writing otherwise. Ideas that we receive
unsolicited will be treated as property owned by The Walnut Company and will not be returned to you.
7. Privacy &amp; Security
7.1 Login Required. In order to access some of the Offerings on this Site, you will be asked to set up an
account, and select a user name and password. Our account registration page requests certain personal
information from you (&quot;Registration Info&quot;). You will have the ability to maintain and periodically update
your Registration Info as you see fit. By registering, you agree that all information provided by you as
Registration Info is true and accurate and that you will maintain and update this information as required
in order to keep it current, complete, and accurate. You may not select as your user name a name that
you do not have the right to use, or another person&#39;s name with the intent to impersonate that person.
You may not transfer your account to anyone else without our prior written permission.
7.2 Passwords &amp; Security. If you register for an account on the Site, you agree that you are responsible
for maintaining the security and confidentiality of your password and that you are fully responsible for
all activities that occur under your account. Therefore, you must take reasonable steps to ensure that
others do not gain access to your password and account. Our employees will never ask you for your
password.
7.3 Information Sharing. If you register for an account on the Site, you agree that your information may
be shared with suppliers, vendors, Escrow Services, and/or third-party processors for merchant
processing, billing, age verification, and membership perks.
7.4 Usage Requirements. You represent and warrant that you are at least 18 years of age. If you are
agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are
authorized to agree to these Terms on that organization or entity&#39;s behalf and bind them to these Terms
(in which case, the references to &quot;you&quot; and &quot;your&quot; in these Terms, except for in this sentence, refer to
that organization or entity. You will only use the Offerings on this site in a manner that complies with all
applicable laws.
7.5 Transactions. If you wish to purchase any product or service made available through the Offerings
(each such purchase, a &quot;Transaction&quot;) you may be asked to supply certain information relevant to your
Transaction, including without limitation, your credit card number, the expiration date of your credit
card, your billing address, and your contact information. YOU REPRESENT AND WARRANT THAT YOU
HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) UTILIZED IN CONNECTION WITH ANY

TRANSACTION. By submitting such information, you grant to The Walnut Company the right to provide
such information to third parties for purposes of facilitating the completion of Transactions initiated by
you or on your behalf.
8. Prohibited Activities
8.1 Prohibited Activities. You agree to use the Site only for lawful purposes and in accordance with
these Terms. You agree to not use the Site:
 In any way that violates any applicable federal, state, local, or international law or regulation
(including, without limitation, any laws regarding the export of data or software to and from the
US or other countries).
 For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by
exposing them to inappropriate content, asking for personally identifiable information, or
otherwise.
 To send, knowingly receive, upload, download, use, or re-use any material that does not comply
with the Content Standards set out in these Terms.
 To impersonate or attempt to impersonate the Company, a Company employee, another user,
or any other person or entity.
 To engage in any other conduct that restricts or inhibits anyone&#39;s use or enjoyment of the Site,
or which, as determined by us, may harm the Company or users of the Site, or expose them to
liability.
 If you are a citizen or resident of any member state of the European Union or the European
Economic Area, regardless of whether accessing the Site from the United States or elsewhere.
Additionally, you agree not to:
 Communicate with, or transfer or receive payment to or from, any party introduced through the
Site or our Offerings, except as enabled or instructed through the Site and our Offerings.
 Transfer or receive payment to or from any party related to any Transaction except as allowed
on the Site and via our Offerings, including the use of an Escrow Service.
 Use the Site in any manner that could disable, overburden, damage, or impair the site or
interfere with any other party&#39;s use of the Site, including their ability to engage in real time
activities through the Site.
 Use any robot, spider, or other automatic device, process, or means to access the Site for any
purpose, including monitoring or copying any of the material on the Site.
 Use any manual process to monitor or copy any of the material on the Site, or for any other
purpose not expressly authorized in these Terms of Use, without our prior written consent.
 Use any device, software, or routine that interferes with the proper working of the Site.
 Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
technologically harmful.
 Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site,
the server on which the Site is stored, or any server, computer, or database connected to the
Site.
 Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.
 Otherwise attempt to interfere with the proper working of the Site.

Failure to refrain from the prohibited activities listed in this section may cause us to take any actions we
deem necessary to remedy the infraction without refund or other consideration to you, including (but
not limited) terminating your account or the offerings, technically preventing you from accessing our
Site or Offerings, or pursuing criminal or civil action.
9. Disclaimer
9.1 ALL CONTENT AND OFFERINGS ON THIS SITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;
BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE
WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY
THAT (A) THE CONTENT OR OFFERINGS WILL MEET YOUR REQUIREMENTS, (B) THE CONTENT,
OFFERINGS OR SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE INFORMATION
OR RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE CONTENT OR OFFERINGS OFFERED WILL
BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY CONTENT OR OFFERINGS
PURCHASED OR OBTAINED BY YOU FROM THE SITE, FROM US OR OUR AFFILIATES WILL MEET YOUR
EXPECTATIONS OR NEEDS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.
9.2 THIS SITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL
ERRORS. WE MAY MAKE CHANGES TO THE CONTENT AND OFFERINGS ON THIS SITE AT ANY TIME
WITHOUT NOTICE TO YOU. THE CONTENT AVAILABLE ON THIS SITE MAY BE OUT OF DATE, AND WE
MAKE NO COMMITMENT TO UPDATE SUCH CONTENT.
THE USE OF THE OFFERINGS THROUGH THIS SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND
WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES YOU INCUR AS A
RESULT.
9.3 WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED THROUGH A THIRD PARTY,
OR IN CONNECTION WITH THIS SITE, AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS
ARE CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION
WITH ANY OFFERINGS OR CONTENT AVAILABLE ON OR THROUGH THIS SITE FROM A THIRD PARTY IS
PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY OTHER OF OUR AFFILIATES.
9.4 WE RESERVE THE SOLE RIGHT TO EITHER MODIFY OR DISCONTINUE THE SITE, INCLUDING ANY
OFFERINGS OR FEATURES THEREIN, AT ANY TIME WITH OR WITHOUT NOTICE TO YOU. WE SHALL NOT
BE LIABLE TO YOU OR ANY THIRD PARTY SHOULD WE EXERCISE SUCH RIGHT. MODIFICATIONS MAY
INCLUDE, BUT ARE NOT LIMITED TO, THE ADDITION OF FREE OR FEE-BASED SERVICES OR CHANGES TO
LIMITATIONS ON ALLOWABLE CONTENT. ANY NEW FEATURES THAT AUGMENT OR ENHANCE THE THEN-
CURRENT OFFERINGS ON THIS SITE SHALL ALSO BE SUBJECT TO THESE TERMS OF USE. SOME STATES OR
JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE
LIMITATIONS MAY NOT APPLY TO YOU. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION
10. Limitation of Liability &amp; Indemnification
10.1 IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY
DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE,
DATA OR PROFIT LOSS; YOUR USE OF THE INFORMATION OR CONTENT CONTAINED ON THE SITE; OR
YOUR RELIANCE UPON THE ACCURACY OF INFORMATION CONTAINED ON THE SITE, WHETHER OR NOT
WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY,

ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS SITE OR OF ANY WEBSITE REFERENCED OR
LINKED TO FROM THIS SITE.
FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY PROMISES REGARDING OUR
OFFERINGS OR CONTENT OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS WITH THE
THIRD PARTY THROUGH THIS SITE, INCLUDING WITHOUT LIMITATION THE SALE OF PRODUCTS OR
SERVICES.
SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. PLEASE CONSULT THE
LAWS IN YOUR JURISDICTION.
10.2 You agree to defend, indemnify, and hold us and our Affiliates harmless from all liabilities, claims,
and expenses, including attorney&#39;s fees that may arise from your use or misuse of this Site or any of the
content contained therein. We reserve the right, at our own expense, to assume the exclusive defense
and control of any matter otherwise subject to indemnification by you, in which event you will
cooperate with us in asserting any available defenses.
11. Termination of Use
11.1 Grounds for Termination. You agree that we may, at our sole discretion, terminate, or suspend
your access to all or part of the Site with or without notice and for any reason, including, without
limitation, breach of these Terms. Any suspected fraudulent, abusive, or illegal activity may be grounds
for barring your access to this Site, and reporting you to the proper authorities, if necessary.
11.2 No Right to Offerings Upon Termination. Upon termination and regardless of the reason(s)
motivating such termination, your right to use the Offerings available on this Site will immediately cease.
We shall not be liable to you or any third party for any claims for damages arising out of any termination
or suspension or any other actions taken by us in connection therewith. Sections 1 and 3-10 of these
Terms shall survive any termination.
12. Miscellaneous Provisions
12.1 Governing Law and Venue. This Agreement shall be governed by the laws of Texas,
notwithstanding conflicts of law principles. Any dispute arising under or related to this Agreement shall
be submitted exclusively to a state district court or county court in Travis County, Texas. By accessing
the Site, you consent to the jurisdiction of such court. The Walnut Company makes no representation
that the Site is appropriate for use in other locations, and accessing them from locations where their
contents are illegal is prohibited. Those who choose to access this Site from other locations do so of
their own initiative and are responsible for compliance with local laws.
12.2 Limitation on Time to File Claims. ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT
OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR
AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
PERMANENTLY BARRED.
12.3 Notices. All notices to The Walnut Company or thewalnutco.com shall be in writing and shall be
sent to info@thewalnutco.com. You agree to allow us to submit notices to you using the email address
provided by you in the Registration Info. Any notices or communication under these Terms will be
deemed delivered to the party receiving such communication on the delivery date when transmitted by
email.

12.5 No Resale Right. You agree not to sell, resell, reproduce, duplicate, distribute, copy, or use for any
commercial purposes any portion of this Site, or use of or access to this Site or Offerings provided
through this Site, beyond the limited rights granted to you under Section 4 of these Terms.
12.6 Force Majeure. In addition to any excuse provided by applicable law, we shall be excused from
liability for non-delivery or delay in delivery of Offerings available through our Site arising from any
event beyond our reasonable control, whether or not foreseeable by either party, including but not
limited to: labor disturbance, war, fire, accident, adverse weather, inability to secure transportation,
governmental act or regulation, and other causes or events beyond our reasonable control, whether or
not similar to those which are enumerated above.
12.7 Severability. If any part of these Terms is held invalid or unenforceable, that portion shall be
construed in a manner consistent with applicable laws to reflect, as nearly as possible, the original
intentions of the parties, and the remaining portions shall remain in full force and effect.
12.8 No Waiver. Any failure by us to enforce or exercise any provision of these Terms or related rights
shall not constitute a waiver of that right or provision.
12.9 Entire Agreement. These Terms and Conditions of Use and the Privacy Policy constitute the entire
agreement and understanding between the parties concerning the subject matter hereof and supersede
all prior agreements and understandings of the parties with respect thereto. These Terms may NOT be
altered, supplemented, or amended by the use of any other document(s). To the extent that anything in
or associated with this Site is in conflict or inconsistent with these Terms, these Terms shall take
precedence.
                    </pre>
                </div>

            </div>
        </div>
    </div>
</main>