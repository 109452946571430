import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-notificationempty',
  templateUrl: './notificationempty.component.html',
  styleUrls: ['./notificationempty.component.scss']
})
export class NotificationemptyComponent implements OnInit {


  @Input() message:string;

  constructor() { }

  ngOnInit(): void {
  }

}
