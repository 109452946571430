
<main>
    	<ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="columnimg right"></div>
    <div class="columntext ">
        <div class="col-md-6">
            <div class="  mb-4 h-md-250">
                <div class=" d-flex flex-column align-items-center">
                    <div style="text-align: center;">
                        <!-- <img src="assets/images/walnutlogo.svg" style="padding-bottom: 15px;"> -->
                        <div style="padding-bottom: 15px;">
                            <img src="../../../../assets/images/logo.png" alt="">
                        </div>
                    </div>
                    <form name="form" style="text-align: center;" (keyup.enter)="f.form.valid && login()"  #f="ngForm" novalidate>
                        <h1 class="display-5" style="font-weight: 700">
                            Build your <span style="font-weight: 400">Career</span>            
                          </h1>
                          <p class=" card-text mb-auto">Login to find your next big project</p>
                          <div class="form-group" style="margin-top: 40px;text-align: left;">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #171717">Email
                                ID</label>
                            <input type="email" autocomplete="off" class="form-control" placeholder="type here" style="padding-top: 25px; padding-bottom: 25px" name="email" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$">
                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.pattern">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group" style="text-align: left;">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #171717">Password
                                </label>
                            <div style="position: relative;">
                                <input   [type] = "passwordType ? 'password' : 'text'" autocomplete="off" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required minlength="6" placeholder="type here" style="padding-top: 25px; padding-bottom: 25px">
                            <i-feather [name]="passwordType ? 'eye' : 'eye-off' " (click) = passwordView() style="position: absolute;top: 13px; right: 18px;cursor: pointer; color: #9f9f9f;width: 20px;"></i-feather>

                            </div>
                            <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                                <div *ngIf="password.errors.required">Password is required</div>
                                <div *ngIf="password.errors.minlength">The password must contain at least 6 characters</div>
                            </div>
                        </div>

                        <!-- <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px; ">By signing up, I agree to the <span data-toggle="modal" data-target="#termsModal" class="termstext">Terms and
                                Conditions</span></p> -->

                        <button *ngIf="submit == true" type="button" [disabled] = "f.form.invalid" (click)="login()" class="btn proHirebutton btn-block" style="margin-top: 15px;padding: 12px 0px 12px 0px;">Login</button>
                        <button *ngIf="loading == true" class="btn proHirebutton btn-block" style="margin-top: 15px;padding: 12px 0px 12px 0px;">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                        </button>
                        <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px;text-align: center;font-size: 16px;"> Don't have an account? <span class="termstext" routerLink="/onboard/signup">Sign up</span></p>
                        <p class=" card-text mb-auto proHireSubHeading termstext" style="margin-top: 10px;text-align: center;font-size: 16px;color: #0066FF;" data-toggle="modal" data-target="#forgetpassword"> Forgot Password</p>
                    </form>
                </div>

            </div>
        </div>

    </div>


    <!--Terms and Conditions-->
    <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 700;">User Agreement</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body terms-modal-body">


                    This User Agreement (this “Agreement”) is a contract between you (“you” or “User”) Upwork Global Inc. (“Upwork,” “we,” or “us”) and our affiliates Upwork Escrow Inc. (“Upwork Escrow”) and, to the extent expressly stated, Elance Limited ("Elance Ltd.").
                    You must read, agree to, and accept all of the terms and conditions contained in this Agreement to be a User of our website located at www.upwork.com or any part of the rest of the Site (defined in the Site Terms of Use) or the Site
                    Services (defined in the Site Terms of Use). <br><br> This Agreement includes and hereby incorporates by reference the following important agreements, as they may be in effect and modified from time to time: Site
                    Terms of Use; Fee and ACH Authorization Agreement; Cookie Policy; Privacy Policy; Mark Use Guidelines; Freelancer Membership Agreement; Proprietary Rights Infringement Reporting Procedures; Upwork App Software License Agreement; API
                    Terms of Use; and the escrow instructions as applicable to any Service Contract you enter into with another User, specifically the Hourly, Bonus, and Expense Payment Agreement with Escrow Instructions; and Fixed-Price Escrow Instructions.
                    This Agreement also incorporates, for any User using the Upwork Direct Contract Service, Upwork Direct Contract Terms and Direct Contract Escrow Instructions. These agreements are collectively, with this Agreement, called the “Terms
                    of Service”.
                    <br><br> Subject to the conditions set forth herein, Upwork may, in its sole discretion, amend this Agreement and the other Terms of Service at any time by posting a revised version on the Site. Upwork will provide reasonable advance
                    notice of any amendment that includes a Substantial Change (defined below), by posting the updated Terms of Service on the Site, providing notice on the Site, and/or sending you notice by email. If the Substantial Change includes an
                    increase to Fees charged by Upwork, Upwork will provide at least 30 days’ advance notice of the change, but may not provide any advance notice for changes resulting in a reduction in Fees or any temporary or promotional Fee change.
                    Any revisions to the Terms of Service will take effect on the noted effective date (each, as applicable, the “Effective Date”).
                    <br><br> YOU UNDERSTAND THAT BY USING THE SITE OR SITE SERVICES AFTER THE EFFECTIVE DATE, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE, INCLUDING THE ARBITRATION PROVISION IN SECTION 14 OF THIS AGREEMENT (SUBJECT TO YOUR RIGHT TO
                    OPT OUT OF THE ARBITRATION PROVISION AS PROVIDED IN SECTION 14). IF YOU DO NOT ACCEPT THE TERMS OF SERVICE IN ITS ENTIRETY, YOU MUST NOT ACCESS OR USE THE SITE OR THE SITE SERVICES AFTER THE EFFECTIVE DATE EXCEPT AS PERMITTED BY THE
                    SITE TERMS OF USE.
                    <br><br> IF YOU AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR AGENCY, OR IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND THAT
                    ENTITY OR AGENCY TO THE TERMS OF SERVICE AND AGREE THAT YOU ARE BINDING BOTH YOU AND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, “YOU” AND “YOUR” WILL REFER AND APPLY TO YOU AND THAT ENTITY OR AGENCY.
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <button type="button" class="btn btnNoBackground">Read More</button>
                </div>
            </div>
        </div>
    </div>


  
  <!--forget passeword Modal -->
  <div class="modal fade" id="forgetpassword" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"  aria-hidden="true">
    <div class="modal-dialog password-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >Forgot your password?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><img src="../../../../../assets/icons/cross.svg"alt="" ></span>
          </button>
        </div>
        <form name="form" (keyup.enter)="fo.form.valid && forgetpass()"  #fo="ngForm" novalidate>
            <div class="modal-body">
                <p style="font-size: 14px;">Enter your email address that you used to register. We'll send you an email with a link to reset your password.</p>
                <div class="form-group" style="margin-top: 40px;">
                    <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Your Registered Email
                        ID</label>
                    <input type="email" autocomplete="off" class="form-control" placeholder="type here" style="padding-top: 15px; padding-bottom: 15px" name="emailforget" [(ngModel)]="model.emailforget" #emailforget="ngModel" [ngClass]="{ 'is-invalid': fo.submitted && emailforget.invalid }" required
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$">
                    <div *ngIf="fo.submitted && emailforget.invalid" class="invalid-feedback">
                        <div *ngIf="emailforget.errors.required">Email is required</div>
                        <div *ngIf="emailforget.errors.pattern">Email must be a valid email address</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-footer reset" data-dismiss="modal">Cancel</button>
                <button type="button" [disabled]="fo.form.invalid" (click)="forgetpass()" class="btn btn-footer apply">Submit</button>
            </div>
        </form>
      </div>
    </div>
  </div>

   <!--forget passeword success Modal -->
   <div class="modal fade" id="success" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"  aria-hidden="true">
    <div class="modal-dialog password-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body" >
            <h5>Password reset email sent</h5>
            <p class="mt-4" style="font-size: 14px;">An email has been sent to your rescue email address, <b>{{registeredEmail}}</b>. Follow the directions in the email to reset the password.</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-footer apply" data-dismiss="modal">Done</button>
        </div>
      </div>
    </div>
  </div>
    <!-- loading modal -->
    <!-- <div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="loadingmodal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content loadingmodal" style="padding: 0;">
    
                <div class="modal-body">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span class="loadText">Loading please wait...</span>
                </div>
    
            </div>
        </div>
    </div> -->

</main>