<!-- <app-header></app-header> -->

<div class="main-container">
    <ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

    <div class="container" *ngIf="loading == false; else elseBlock">
        <ng-container *ngIf="projects.length != 0; else templates">

            <div class="contant-box">
                <div class="row header-box">
                    <div class="col-lg-3">
                        <p class="browse">Complete Projects</p>
                    </div>

                </div>
                <div class="card-container">
                    <div class="card" *ngFor="let project of projects | reverse; let i = index" style="cursor: pointer;">
                        <div class="card-body" routerLink="/dashboard/completedprojects/{{project._id}}">
                            <div class="row">
                                <div class="col-xl-11">
                                    <h5> {{project.projectName | titlecase}}</h5>
                                    <p class="location">Project Location: <span> {{project.state}}</span></p>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <p class="description"> {{project.description}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 " style="display: flex; flex-wrap: wrap;" > 
                                    <div class="details" style="margin-right: 35px;">
                                        <div>
                                            <i-feather name="briefcase" class="big"></i-feather>
                                        </div>
                                        <div>
                                            <p class="title">License Type</p>
                                            <p class="value"> {{project.licenseType | titlecase}}</p>
                                        </div>
                                    </div>
                                    <!-- <div class="details" style="margin-right: 35px;" >
                                        <div>
                                            <img src="../../../../assets/icons/clock.svg" alt="">
                                        </div>
                                        <div>
                                            <p class="title">Project Duration</p>
                                            <p class="value"> {{project.duration}} Days</p>
                                        </div>
                                    </div> -->
                                    <div class="details" style="margin-right: 35px;" >
                                        <div>
                                            <i-feather name="dollar-sign" class="big"></i-feather>
                                        </div>
                                        <div>
                                            <p class="title">Project Cost</p>
                                            <p class="value"> {{project.budget}} </p>
                                        </div>
                                    </div>
                                    <div class="details" style="margin-right: 35px;" >
                                        <div>
                                            <i-feather name="calendar" class="big"></i-feather>
                                        </div>
                                        <div>
                                            <p class="title">Posted on</p>
                                            <p class="value"> {{project.createdAt | date:'short' : '-0800'}} </p>
                                        </div>
                                    </div>
                             
                                   
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </ng-container>
        <ng-template #templates>
            <div class="card verified">
                <div class="card-body">
                    <app-emptystates [message] = "emptyprojects"></app-emptystates>
                </div>
             </div>        </ng-template>
    </div>
    <ng-template #elseBlock>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
            <app-loading></app-loading>
        </div>
    </ng-template>

</div>