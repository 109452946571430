import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public API = environment.apiUrl;

  constructor(private http : HttpClient,private cookieService: CookieService) { }

  getBrowse(page, licenseType, location) {
    let params = new HttpParams();
    
    params = params.append('limit','3')
    params = params.append('page',page)
    if (location !== '' && licenseType !== '' ) {

      console.log(location)
      console.log(licenseType)
      params = params.append('location',location)
      params = params.append('licence',licenseType)
        return this.http.get(this.API + '/browse/project', {params:params});
    }

    if(location !== '') {
      console.log(location)
      console.log(licenseType) 
      params = params.append('location',location)

      return this.http.get(this.API + '/browse/project', {params:params});
    }
    if(licenseType !== '') {
      console.log(location)
      console.log(licenseType)
      
      params = params.append('licence',licenseType)
      return this.http.get(this.API + '/browse/project', {params:params});
    }
    return this.http.get(this.API + '/browse/project', {params:params});

  }
  getActiveprojects(){
    return this.http.get( this.API + '/active/project' );
  }
  getcomplete(){
    return this.http.get( this.API + '/completed/project' );
  }
  getActivebids(page){
    let params = new HttpParams();
    
    params = params.append('limit','1')
    params = params.append('page',page)
    return this.http.get( this.API + '/active/bids',{params:params} );
  }
  filterbrowse(location, licenseType){

    if (location !== '' && licenseType !== '' ) {

      console.log(location)
      console.log(licenseType)
        const params = new HttpParams()
        .set('location', location)
        .set('licence', licenseType);
        return this.http.get(this.API + '/browse/project', {params});
    }

    if(location !== '') {
      console.log(location)
      console.log(licenseType) 
      const params = new HttpParams()
      
      .set('location', location);
      return this.http.get(this.API + '/browse/project', {params});
    }
    if(licenseType !== '') {
      console.log(location)
      console.log(licenseType)
      const params = new HttpParams()
      
      .set('licence', licenseType);
      return this.http.get(this.API + '/browse/project', {params});
    }
   
  }

  browseDetail(id){
    return this.http.get(this.API + '/project/card/' + id)
  }

  postFAQ(data,id){
    return this.http.post(this.API + '/post/'+ id, data)
  }
  closedFAQ (id){
    const params = new HttpParams()
      
    .set('closed', 'true');
    return this.http.get(this.API + '/project/card/' + id,{params})
  }

  newFAQ(id){
    return this.http.get(this.API + '/project/card/' + id)
  }
  sendBid(data, id){
    return this.http.post(this.API+ '/bid/' + id, data)
  }
  sendRebid(data, id){
    return this.http.post(this.API+ '/rebid/' + id, data)
  }
  rejectbid(page){
    let params = new HttpParams();
    params = params.append('action','reject')
    params = params.append('limit','1')
    params = params.append('page',page)
      
    return this.http.get(this.API + '/active/bids', {params:params})
  }
  projectGuard(){
    return !!this.cookieService.check('token')
  }

  projectsubmit(id) {
    return this.http.post(this.API + '/final/submit/' + id,{})
  }

}
