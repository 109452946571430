import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../service/auth/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class EmailverifyGuard implements CanActivate {
  constructor(private router: Router, private cookie: CookieService,public service: AuthServiceService ){}
  // canActivate() {
  //   if(!this.cookie.check('email')){
  //     this.router.navigate(['/onboard/signup'])
  //     alert('The signup was detected from a different browser/system, kindly finish the registration using the same or start afresh.')

  //     return false;
  //   }
  //   console.log('navigate to signup');
  //   return true;

  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    console.log(route);
    console.log(state);
    const id = route.params.id;
    return this.service
      .emailverify(id)
      .toPromise()
      .then((data) => {
        console.log(data);

        return true;
      })
      .catch((err) => {
        console.log(err);

        this.router.navigate(['/onboard/signup']);
        return false;
      });
  }
  
}
