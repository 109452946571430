<main>
    <div class="columnimg right"></div>
    <img src="../../../assets/images/logo.png" alt="" style="padding: 21px 0 0 42px;cursor: pointer;" routerLink="/onboard/login">

    <div class="columntext ">
        <div class="col-md-6">
            <div class="  mb-4 h-md-250">
                <div class=" d-flex flex-column align-items-start">
                    <form name="form" (keyup.enter)="f.form.valid && resetpass()" (ngSubmit)="f.form.valid && resetpass() " #f="ngForm" [mustMatch]="['password', 'confirmPassword']" novalidate>
                        <h1 class="display-5" style="font-weight: 700;">Reset <span style="font-weight: 400;">your
                                password</span></h1>
                        <!-- <p class=" card-text mb-auto">It's our mission to help you find the next big project.</p> -->
                        <div class="form-group" style="margin-top: 40px;">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">New password</label>
                                <input type="password" autocomplete="off" type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required minlength="6" style="padding-top: 15px; padding-bottom: 15px;">
                                <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                                    <div *ngIf="password.errors.required">Password is required</div>
                                    <div *ngIf="password.errors.minlength">Password must be at least 6 characters</div>
                                </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Re-enter password
                                </label>
                                <input type="password" autocomplete="off" type="password" class="form-control" name="confirmPassword" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel" [ngClass]="{ 'is-invalid': f.submitted && confirmPassword.invalid }" required style="padding-top: 15px; padding-bottom: 15px;">
                                <div *ngIf="f.submitted && confirmPassword.invalid" class="invalid-feedback">
                                    <div *ngIf="confirmPassword.errors.required">Confirm Password is required</div>
                                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords must match</div>
                                </div>
                        </div>

                        <!-- <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px; ">By signing up, I agree to the <span data-toggle="modal" data-target="#termsModal" class="termstext">Terms and
                                Conditions</span></p> -->

                        <button *ngIf="submit == true" type="submit" class="btn proHirebutton btn-block" style="margin-top: 15px;">Submit</button>
                        <button *ngIf="loading == true" class="btn proHirebutton btn-block" style="margin-top: 15px;">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                        </button>
                        <!-- <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px;text-align: center;font-size: 16px;"> Don't have an account? <span class="termstext" routerLink="/engineer/signup">Sign up.</span></p>
                        <p class=" card-text mb-auto proHireSubHeading termstext" style="margin-top: 20px;text-align: center;font-size: 16px;color: #0066FF;" data-toggle="modal" data-target="#forgetpassword"> Forget password?</p> -->

                    </form>
                </div>

            </div>
        </div>

    </div>


    <!--Terms and Conditions-->
    <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 700;">User Agreement</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body terms-modal-body">


                    This User Agreement (this “Agreement”) is a contract between you (“you” or “User”) Upwork Global Inc. (“Upwork,” “we,” or “us”) and our affiliates Upwork Escrow Inc. (“Upwork Escrow”) and, to the extent expressly stated, Elance Limited ("Elance Ltd.").
                    You must read, agree to, and accept all of the terms and conditions contained in this Agreement to be a User of our website located at www.upwork.com or any part of the rest of the Site (defined in the Site Terms of Use) or the Site
                    Services (defined in the Site Terms of Use). <br><br> This Agreement includes and hereby incorporates by reference the following important agreements, as they may be in effect and modified from time to time: Site
                    Terms of Use; Fee and ACH Authorization Agreement; Cookie Policy; Privacy Policy; Mark Use Guidelines; Freelancer Membership Agreement; Proprietary Rights Infringement Reporting Procedures; Upwork App Software License Agreement; API
                    Terms of Use; and the escrow instructions as applicable to any Service Contract you enter into with another User, specifically the Hourly, Bonus, and Expense Payment Agreement with Escrow Instructions; and Fixed-Price Escrow Instructions.
                    This Agreement also incorporates, for any User using the Upwork Direct Contract Service, Upwork Direct Contract Terms and Direct Contract Escrow Instructions. These agreements are collectively, with this Agreement, called the “Terms
                    of Service”.
                    <br><br> Subject to the conditions set forth herein, Upwork may, in its sole discretion, amend this Agreement and the other Terms of Service at any time by posting a revised version on the Site. Upwork will provide reasonable advance
                    notice of any amendment that includes a Substantial Change (defined below), by posting the updated Terms of Service on the Site, providing notice on the Site, and/or sending you notice by email. If the Substantial Change includes an
                    increase to Fees charged by Upwork, Upwork will provide at least 30 days’ advance notice of the change, but may not provide any advance notice for changes resulting in a reduction in Fees or any temporary or promotional Fee change.
                    Any revisions to the Terms of Service will take effect on the noted effective date (each, as applicable, the “Effective Date”).
                    <br><br> YOU UNDERSTAND THAT BY USING THE SITE OR SITE SERVICES AFTER THE EFFECTIVE DATE, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE, INCLUDING THE ARBITRATION PROVISION IN SECTION 14 OF THIS AGREEMENT (SUBJECT TO YOUR RIGHT TO
                    OPT OUT OF THE ARBITRATION PROVISION AS PROVIDED IN SECTION 14). IF YOU DO NOT ACCEPT THE TERMS OF SERVICE IN ITS ENTIRETY, YOU MUST NOT ACCESS OR USE THE SITE OR THE SITE SERVICES AFTER THE EFFECTIVE DATE EXCEPT AS PERMITTED BY THE
                    SITE TERMS OF USE.
                    <br><br> IF YOU AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR AGENCY, OR IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND THAT
                    ENTITY OR AGENCY TO THE TERMS OF SERVICE AND AGREE THAT YOU ARE BINDING BOTH YOU AND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, “YOU” AND “YOUR” WILL REFER AND APPLY TO YOU AND THAT ENTITY OR AGENCY.
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                    <button type="button" class="btn btnNoBackground">Read More</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="success" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"  aria-hidden="true">
        <div class="modal-dialog password-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body" >
                <h5>Successful password reset</h5>
                <p class="mt-4" style="font-size: 14px;">You can now use your new password to login to your account</p>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-footer apply" data-dismiss="modal" routerLink = "/onboard/login">Login</button>
            </div>
          </div>
        </div>
    </div>

  

</main>