import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthServiceService } from 'src/app/service/auth/auth-service.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  model: any = {};
  public submit = true;
  public loading = false;

  constructor(public router: Router, private cookieService: CookieService, private authService: AuthServiceService) { }

  ngOnInit(): void {
  
  }
  emailsave() {
    this.submit = false;
    this. loading = true;
    this.authService.signup({'email': this.model.email}).subscribe( (data) => {
      this. loading = false;
      this.submit = true;
      console.log(data)
      this.cookieService.set( 'email', this.model.email );
      this.router.navigate(['/onboard/emailverify']);
    }, err => {
      this.submit = true;
      this. loading = false;
    })
  }

}
