import { ProjectguardGuard } from './../../guard/projectguard.guard';
import { RevisionComponent } from './revision/revision.component';
import { ActiveprojectsdetailComponent } from './activeprojectsdetail/activeprojectsdetail.component';
import { ProjectdetailsComponent } from './projectdetails/projectdetails.component';
import { CompleteprojectsComponent } from './completeprojects/completeprojects.component';
import { ActivebidsComponent } from './activebids/activebids.component';
import { ActiveprojectsComponent } from './activeprojects/activeprojects.component';
import { BrowseprojectComponent } from './browseproject/browseproject.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RejectdetailComponent } from './rejectdetail/rejectdetail.component';

const routes: Routes = [
  {
    path: '', children: [
      { path: '', redirectTo: 'browseprojectS', pathMatch: 'full' },
      {
        path: 'browseprojects', component:BrowseprojectComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'activeprojects', component:ActiveprojectsComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'activebids', component:ActivebidsComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'completedprojects', component:CompleteprojectsComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'browseprojects/:id', component:ProjectdetailsComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'activebids/:id', component:ProjectdetailsComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'activeprojects/:id', component:ActiveprojectsdetailComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'completedprojects/:id', component:ActiveprojectsdetailComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'revision/:id', component:RevisionComponent,canActivate: [ProjectguardGuard]
      },
      {
        path: 'activebids/reject/:id', component:RejectdetailComponent,canActivate: [ProjectguardGuard]
      },
      { path: '**', redirectTo: 'browseprojectS', pathMatch: 'full' },  // Wildcard route for a 404 page

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
