import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthServiceService } from 'src/app/service/auth/auth-service.service';
declare var $ : any;
@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.scss']
})
export class VerifyemailComponent implements OnInit {
  public email;
  
  constructor(private loadingBar: LoadingBarService ,private  cookieService: CookieService, private service: AuthServiceService) { }

  ngOnInit(): void {

    this.email = this.cookieService.get('email');
    console.log(this.email)
  }

  resendEmail(){
    // $('#loadingmodal').modal('show');   
   this.loadingBar.start(0)
    this.service.resendEmail({'email': this.email}).subscribe( () => {
      $('#success').modal('show');   
      // $('#loadingmodal').modal('hide');  
      this.loadingBar.stop();
    }, err => {
      this.loadingBar.stop()
    })
  }

}
