import { ProjectService } from './../service/project/project.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProjectguardGuard implements CanActivate {
  constructor(private project : ProjectService, private router : Router){}
  canActivate() {
    if(this.project.projectGuard()){
      console.log('project log true');
      return true;
    }
    console.log('project log false');
    this.router.navigate(['/onboard/login'])
    return false;

  }
  
}
