import { SocketoneService, SockettwoService } from './../../../service/socketconnection.service';
import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DomSanitizer } from '@angular/platform-browser';


declare var $ : any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  passwordType = true

  public submit = true;
  public loading = false;
  public done = false;
  public error = false
  public model: any = {};
  public registeredEmail;
  public username;
  imageurl;

  constructor(private sanitizer: DomSanitizer, private loadingBar: LoadingBarService,  private socket: SocketoneService,private socket2: SockettwoService ,public router: Router, private cookieService: CookieService, private authService: AuthServiceService) { }

  ngOnInit(): void {
  }
 
  login() {

    console.log(this.model);
    this.submit = false;
    this. loading = true;

    this.socket.emit('login',{email: this.model.email, password: this.model.password,owner: 'engineer'})
    this.socket2.emit('engineer_login',{email: this.model.email, password: this.model.password})
    this.authService.login({email: this.model.email, password: this.model.password}).subscribe((data: any) => {
      this. loading = false;
      this. done = true;
      this.cookieService.set( 'token', data.token, 1,'/' );
      
      console.log( data)
      this.authService.showHeader.next(true)
      if(data.profile_verification_complete == false) {
        this.authService.getPaymentURL().subscribe((data: any) => {
          window.open(data.url.url, "_self")
          console.log(data);
          this.getProfileData()
        })
      } else {
        this.cookieService.set( 'header', 'true', 1,'/' );
        this.router.navigateByUrl('/dashboard/browseprojects');
        this.getProfileData()
      }

    },
    err => {
      // $('#loadingmodal').modal('hide');
      this.loadingBar.stop()
      console.log(err);
      this.loading = false;
      this.submit = true;
      this. error = true;
    })
  }

  forgetpass(){
    $('#forgetpassword').modal('hide');
    // $('#loadingmodal').modal('show');
    this.loadingBar.stop();
    this.authService.forgotPassword({'email':this.model.emailforget}).subscribe(() => {
      this.registeredEmail = this.model.emailforget;
      $('#success').modal('show');
      // $('#loadingmodal').modal('hide');
      this.loadingBar.stop()
    }, err =>  this.loadingBar.stop()    )
  }

  getProfileData() {
    this.authService.viewprofile().subscribe((data: any) => {
      console.log(data)
      this.username = data.profile.username;
      if (data.avatar) {
        let TYPED_ARRAY = new Uint8Array(data.avatar.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');

        let base64String = btoa(STRING_CHAR);
        this.imageurl = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        console.log(this.imageurl);
      }
      this.authService.profileData.next(
        {
          username: data.profile.username,
          avatar: this.imageurl,
          block: data.profile.block
        }
      )
    }, err => console.log(err.error.error))
  }


  passwordView() {
    this.passwordType = ! this.passwordType
  }
  
}
