import { ProjectService } from './../service/project/project.service';
import { AuthServiceService } from 'src/app/service/auth/auth-service.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGuardGuard implements CanActivate {
  constructor(private authService: AuthServiceService,private project: ProjectService,private router: Router, private cookie: CookieService){}
  canActivate() {

    if(this.cookie.check('temp')){
      return true;
    }
    this.router.navigate(['/onboard/login'])
    return false;

  }
  
}
