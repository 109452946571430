import { AuthServiceService } from './../../../service/auth/auth-service.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
declare var $ : any;

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss']
})
export class RevisionComponent implements OnInit {

  public id;
  public revisionDetail;
  public page = 1;
  public bidAmount;
  public bidReason;
  public revisionID;
  public files = [];
  public urls = [];
  public revisionInput;
  public docs = [];
  public popupBtn;
  public fileListARR = []
  public loading = true;

  public platformFee = 0;
  public commision = 0;

  constructor(private service: AuthServiceService, private actRoute: ActivatedRoute,  private cookie: CookieService, private loadingBar: LoadingBarService) { }

  ngOnInit(): void {
    this.service.avatarData.next(true)
    this.service.header.next(true);

    this.id = this.actRoute.snapshot.paramMap.get('id');

    this.service.getAllRevision(this.id).subscribe((data: any)=> {
      this.loading = false;
      console.log(data)
      this.revisionDetail = data ;
      this.revisionID = data.revision[data.revision.length - 1]._id
      this.popupBtn = data.revision[data.revision.length - 1]
     
    })
    
  }

  sendBid() {
    if(this.bidAmount != undefined && this.bidReason != undefined ) {

      // $('#exampleModal').modal('show');
      this.loadingBar.start(0)
      this.service.bidOnRevisiton(this.revisionID,{'revisionBidAmount':this.bidAmount,'commission':this.platformFee, 'bidReason': this.bidReason}).subscribe( data => {
        // $('#exampleModal').modal('hide');
        this.loadingBar.stop()
        this.revisionDetail = data ;
        console.log(data)
      })
    } else if(this.bidAmount != undefined && this.bidReason == undefined ) {
      this.service.bidOnRevisiton(this.revisionID,{'revisionBidAmount':this.bidAmount,'commission':this.platformFee}).subscribe( data => {
        // $('#exampleModal').modal('hide');
        this.loadingBar.stop()
        this.revisionDetail = data ;
        console.log(data)
      })
    }
  }
  sendReBid() {
    // $('#exampleModal').modal('show');
    if(this.bidAmount != undefined && this.bidReason != undefined ) {
      this.loadingBar.start(0)

      this.service.rebidRevision(this.revisionID,{'revisionBidAmount':this.bidAmount,"commission": this.platformFee, 'bidReason': this.bidReason}).subscribe( data => {
        // $('#exampleModal').modal('hide');
        this.loadingBar.stop()
        this.revisionDetail = data ;
        console.log(data)
      })
    } else if(this.bidAmount != undefined && this.bidReason == undefined ) {
      this.service.rebidRevision(this.revisionID,{'revisionBidAmount':this.bidAmount,"commission": this.platformFee, }).subscribe( data => {
        // $('#exampleModal').modal('hide');
        this.loadingBar.stop()
        this.revisionDetail = data ;
        console.log(data)
      })
    }

  }

  acceptBid(){
    // $('#exampleModal').modal('show');
    this.loadingBar.start(0)

    this.service.acceptRevision(this.revisionID).subscribe( data => {
      // $('#exampleModal').modal('hide');
      this.loadingBar.stop()
      this.revisionDetail = data ;
      console.log(data)
    })
  }

  async detectFiles(event) {

    this.files = event.target.files;

    let fileName = event.target.files[0].name.split('.').pop();
    let fileExtensionArr = ['png','jpeg','jpg','pdf','xls','doc' ,'docx','dwg','zip','rar','xlsx','txt','ppt','pptx','jfif','xml','svg' ]
    if(!fileExtensionArr.includes(fileName)) {
      	return alert('Unsupported file format. You can upload doc, .xlsx, .zip, .rar, .dwg, .txt, .ppt, .pptx, .docx, .pdf, .xls, .jfif, .xml, .png, .jpg, .jpeg and .svg' )

    }
  
    if (this.files) {
      for (let file of this.files) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });
      // console.log(await toBase64(file).then( data => console.log(data)));
        await toBase64(file).then( data => {
          let newFile = {
            'FileValue': data,
            'FileType' : file.name.split('.').pop(),
            'Filename' : file.name

          }
          this.urls.push(newFile);
          console.log(this.urls)
        })

      }
    }
    Array.from(this.files).forEach((singleFile) => {
      console.log(singleFile);
      
      let filepush = {
        'fileType' : singleFile.type,
        'extension' : singleFile.name.split('.').pop(),
        'originalName' : singleFile.name
      }
        console.log(filepush)
        this.docs.push(filepush);
        this.fileListARR.push(singleFile)
    })
    console.log( this.docs);
    console.log(this.fileListARR)
 
    
  }
  deleteDoc(index){
    console.log(index)
    this.docs.splice(index,1);
    this.fileListARR.splice(index,1)
    this.urls.splice(index,1)
    console.log(this.docs); 
    console.log(this.fileListARR);
    console.log(this.urls); 
  }
  completeRevision() {
    // $('#exampleModal').modal('show');

    console.log(this.docs); 
    let revisionData = {
      
      'fileDetails': this.docs
    }
    if( this.fileListARR.length != 0) {
      this.loadingBar.start(0)

      this.service.uploadRevisionDocs(revisionData, this.revisionID).subscribe(  (response : any) => {
        console.log(response);
        let revisionID = response[response.length - 1]._id
        
        for( let i = 0; i < response.length; i++) {
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': response[i].fileType}) };
  
          this.service.sendKey(response[i].url,  this.fileListARR[i], httpOptions).subscribe( data => {
            console.log( data ) 
            console.log(response[i])
            const httpOptions = { headers: new HttpHeaders({ 'Authorization': this.cookie.get('token')})};
            
          const value = {
            'Key' : response[i].key,
            'url' : response[i].url,
            'docType' : 'engineer',
            'extension': response[i].extension,
            'originalName' : response[i].originalName
          }
          console.log(value)
          console.log(this.revisionID);
          
  
            this.service.saveRevisionDocs(this.revisionID,value,httpOptions).subscribe( (data:any) => {
              console.log(data);
              this.revisionDetail = data ;
              
              this.revisionID = data.revision[data.revision.length - 1]._id
              this.popupBtn = data.revision[data.revision.length - 1]
              // $('#exampleModal').modal('hide');
              this.loadingBar.stop()
              this.docs = []
              this.fileListARR = []
              this.urls = []
              
              // this.router.navigate(['/revision/'+ this.id])
            } , err => this.loadingBar.stop());
          },
          err => this.loadingBar.stop() );
  
        }
      })
    } else {
    }
  }

  plaformfee() {

    if(this.bidAmount > 100) {
      this.platformFee =Math.round( Number((this.bidAmount)/100) * 10)
      this.commision = Math.round(Number(this.bidAmount) +  Number(this.platformFee))
    
    }  else {
      // this.platformFee =Math.round( Number((this.bidAmount)/100) * 10)
      // this.commision = Math.round(Number(this.bidAmount) +  Number(this.platformFee))
      this.commision = 0
      this.platformFee = 0

    }
  }

  showClientDocLabel(data) {
    return data.some(oneData => oneData.docType.includes('client'));
  }

  showEngineerDocLabel(data) {
    return data.some(oneData => oneData.docType.includes('engineer'));
  }


}
