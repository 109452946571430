<div class="main-box" *ngIf="loading == false; else elseBlock">
    <div class="container">
        <div *ngIf="verified == true; else notVerified" class=" verified" style="text-align: center;">
            <img src="../../../../assets/other/verified.gif" alt="">
            <p style="text-align: center;">
                <span style="font-size: 18px; font-weight: 700;">Your email has been verified</span> <br>By clicking register button you can register your account</p>
            <div class="text-center">
                <button class="btn " type="submit" routerLink="/onboard/register">Let's Register</button>
            </div>
        </div>
        <ng-template #notVerified>
            <div class=" verified">
               
                <app-emptystates message = "Your email verification failed."></app-emptystates>
                <p style="text-align: center;">Please check your email or Re-verify the email.</p>

             </div>
        </ng-template>
    </div>    
</div>
<ng-template #elseBlock>
    <div class="d-flex align-items-center justify-content-center" style="background-color: white; height: 100vh;">
        <app-loading></app-loading>
    </div>
</ng-template>