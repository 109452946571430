import { SockettwoService } from './../../../service/socketconnection.service';
import { SocketoneService } from 'src/app/service/socketconnection.service';

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { HttpHeaders,  HttpClient } from '@angular/common/http';

import { FormBuilder, FormGroup ,FormControl, Validators, FormArray} from '@angular/forms';
import { AuthServiceService } from 'src/app/service/auth/auth-service.service';
import { ConfirmedValidator } from '../../../validator/confirmed.validator';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ValidatePhone } from 'src/app/validator/phone.validator';

// declare var $: any;
@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})


export class RegisterComponent implements OnInit, AfterViewInit {
   
	states = ["Alaska",
	"Alabama",
	"Arkansas",
	"American Samoa",
	"Arizona",
	"California",
	"Colorado",
	"Connecticut",
	"District of Columbia",
	"Delaware",
	"Florida",
	"Georgia",
	"Guam",
	"Hawaii",
	"Iowa",
	"Idaho",
	"Illinois",
	"Indiana",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Massachusetts",
	"Maryland",
	"Maine",
	"Michigan",
	"Minnesota",
	"Missouri",
	"Mississippi",
	"Montana",
	"North Carolina",
	" North Dakota",
	"Nebraska",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"Nevada",
	"New York",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Puerto Rico",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Virginia",
	"Virgin Islands",
	"Vermont",
	"Washington",
	"Wisconsin",
	"West Virginia",
	"Wyoming"]
  
	myforms: FormGroup = new FormGroup({});
	submitted = false;
  
	files = []
	progress: number;
	page = 1;
	isLazybutton = false

	confirmpasswordType = true
	newpasswordType = true
  
  
  
	public styles = {
	  backgroundImage: `url(../../../../assets/other/user.svg)`
	};
	public imagePath;
	public avatar = false;
	public imgURL: any = "../../../../assets/other/user.svg";
	public nextbtn = true;
	public submitbtn = false;
	public activelink = true;
	public thirdTab = false;
	public progressvalue = '0';
	public text = "Personal";
	public docs = [];
  
	public location;
  
	public inputArr = [];
	public Increment = 0;
	urls = []
	model: any = {};
	fileListARR = []

	// public locationOne = []
	// public stateOne = []
  
	// new file modal
  
	public SelectFileArr = []

	public filepush;

	public isButtonClicked = false;
	public past_dates = new Date().toISOString().substring(0, 10)
  
	constructor(
		private loadingBar: LoadingBarService,
		private fb: FormBuilder, private cookie: CookieService, 
		public HttpClient: HttpClient, public router: Router, 
		private cookieService: CookieService, private service: AuthServiceService, 
		private actRoute: ActivatedRoute , private socket1: SocketoneService, private socket2: SockettwoService
		) {}
  
	ngAfterViewInit() {
	  this.service.headerColor.next(true)
	}
	ngOnInit(): void {
        
	    if(this.cookieService.check('token')) {
			this.isLazybutton = true
		}

		this.inputArr.push(this.Increment++);
		console.log(this.inputArr)
		if(this.cookieService.check('token')) {
			this.nextbtn = false;
		}
	  this.myforms = this.fb.group({
  
		firstName: new FormControl(null, [Validators.required]),
		lastName:  new FormControl(null, [Validators.required]),
		phone: new FormControl(null, [ ValidatePhone]),
		password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~@$!%*#.?&])[A-Za-z\d`~@$!%#.*?&]{8,}$/)]),
		confirm_password: new FormControl(null, [Validators.required, ]),
		description: new FormControl(null, [Validators.required]),
		insuranceDoc: new FormControl(null, [Validators.required]),
		license: new FormArray([
		  new FormControl(null, [Validators.required])
		]),
		location: new FormArray([
		  new FormControl(null, [Validators.required])
		]),
		// documents: new FormArray([
		//   new FormControl(null, [Validators.required])
		// ]),
		licenseNumber: new FormArray([
			new FormControl(null, [Validators.required])
		  ]),
		expDate: new FormArray([
			new FormControl(null, [Validators.required])
		  ])
  
	  }, {
		validator: ConfirmedValidator('password', 'confirm_password')
	  });
  
	  const id = this.actRoute.snapshot.paramMap.get('id');
	  console.log(id)
	  if(this.cookie.check('bankverify')){
		this.activelink = undefined;
		this.thirdTab = true;
		this.progressvalue = '75%';
		this.submitbtn = false;
		this.text = "Verify bank";
	  }

	}
  
	// convenience getter for easy access to form fields
	get f() {
	  return this.myforms.controls;
	}
  

	getLocationArr() {
	  return (this.myforms.get('location') as FormArray).controls;
	}

	addInput() {

	let ArrLength = this.myforms.get('license').value.length - 1 ;
  
	  ( < FormArray > this.myforms.get('license')).push(new FormControl(this.myforms.get('license').value[ArrLength], [Validators.required]));
	  ( < FormArray > this.myforms.get('location')).push(new FormControl(null, [Validators.required]));
	  ( < FormArray > this.myforms.get('expDate')).push(new FormControl(null, [Validators.required]));
	  ( < FormArray > this.myforms.get('licenseNumber')).push(new FormControl(null, [Validators.required]));

	//   console.log(this.myforms.get('license').value, "form Array")


     
	}
  
	deleteFeild(i) {
  
	  ( < FormArray > this.myforms.get('license')).removeAt(i);
	  ( < FormArray > this.myforms.get('location')).removeAt(i);
	  ( < FormArray > this.myforms.get('expDate')).removeAt(i);
	  ( < FormArray > this.myforms.get('licenseNumber')).removeAt(i);
	  

	  this.SelectFileArr.splice(i, 1)
	  this.urls.splice(i, 1)
	  this.docs.splice(i, 1)
	  this.fileListARR.splice(i, 1)
  
	  for (let j = 0; j < this.SelectFileArr.length; j++) {
		this.SelectFileArr[j].index = j;
		this.urls[j].fileIndex = j;
		this.docs[j].fileIndex = j;
		this.fileListARR[j].fileIndex = j;
		console.log(this.SelectFileArr[j]);
	  }
  
	  console.log(this.SelectFileArr);
	  console.log(this.urls);
	  console.log(this.fileListARR);
  
  
	}

  
	readURL(files) { 
	  console.log(files.target.files[0]);
  
	  if (files.length === 0)
		return;
		if( Math.round((files.target.files[0].size))  > 10485760) {
			alert( 'The file size can not exceed 10MB.')
			return
		}
		let fileName = files.target.files[0].name.split('.').pop();

		if(fileName == 'jpg' || fileName == 'jpeg' || fileName == 'png' ) {
			this.avatar = true;
			console.log(this.avatar)
		
			var reader = new FileReader();
			this.imagePath = files;
			reader.readAsDataURL(files.target.files[0]);
		
			reader.onload = (_event) => {
			  this.imgURL = reader.result;
			  console.log(this.imgURL)
			  this.styles = {
				backgroundImage: `url(${this.imgURL})`
			  };
			}
		} else {
			return alert('Unsupported image format. You can upload jpg, jpeg and png images')
		}

		
	  

  
	}
  
	next() {
	  this.nextbtn = false;
	  this.submitbtn = true;
	  this.activelink = false;
	  this.progressvalue = '40%';
	  this.text = "Professional";
	}
	previous() {
	  this.nextbtn = true;
	  this.submitbtn = false;
	  this.activelink = true;
	  this.progressvalue = '0';
	  this.text = "Personal";
	}

	async detectFiles(event) {
		this.files = event.target.files;
		  console.log(event.target.files[0].name.split('.').pop())
		  let fileName = event.target.files[0].name.split('.').pop()
		  if(fileName == 'jpg' || fileName == 'jpeg' || fileName == 'png' || fileName == 'pdf' || fileName == 'doc' || fileName == 'docx'  || fileName == 'dwg') {
			if (this.files) {
				for (let file of this.files) {
				  const toBase64 = file => new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				});
				  await toBase64(file).then( data => {
					let newFile = {
					  'FileValue': data,
					  'FileType' : file.name.split('.').pop(),
					  'Filename' : file.name
					}
					this.urls.push(newFile);
					console.log(this.urls)
				  })
				}
			  }
		  
			  Array.from(this.files).forEach((singleFile) => {
					 
				this.filepush = {
				  'fileType' : singleFile.type,
				  'extension' : singleFile.name.split('.').pop(),
				  'originalName': singleFile.name
				}
				  console.log(this.filepush)
				  this.docs.push(this.filepush);
				  this.fileListARR.push(singleFile)
			  })
			  console.log(this.docs); 
			  console.log(this.fileListARR);
		  } else {
			  return alert('Unsupported file format. You can upload jpg, jpeg, png, pdf, dwg, zip, rar and xls files.')
		  }


		  
	}
  
  
  
	onSubmit() {

		this.isButtonClicked = true
	  console.log(this.myforms);
	  let name = this.myforms.value.firstName.concat(" ", this.myforms.value.lastName)
	  console.log(this.myforms, name);
  
	//   $('#exampleModal').modal('show');
	this.loadingBar.start(0)
	  console.log(this.avatar);
	  console.log(this.model);
  
	  const formData = new FormData();
  
	  formData.append('username', name);
	  formData.append('description', this.myforms.value.description);
	  formData.append('password', this.myforms.value.password);
	  formData.append('phone', this.myforms.value.phone);
	  formData.append('fileType',this.docs[0].fileType);
	  formData.append('extension',this.docs[0].extension);
	  formData.append('fileName',this.docs[0].originalName);

	  for (let index = 0; index < ( < FormArray > this.myforms.get('license')).value.length; index++) {
		  formData.append('Details[' + index + '][licence]', ( < FormArray > this.myforms.get('license')).value[index]);
		  formData.append('Details[' + index + '][state]', ( < FormArray > this.myforms.get('location')).value[index]);
		  formData.append('Details[' + index + '][licenseNumber]', ( < FormArray > this.myforms.get('licenseNumber')).value[index]);	  
		  formData.append('Details[' + index + '][expDate]', ( < FormArray > this.myforms.get('expDate')).value[index]);	  
	  }
	  if (this.avatar == true) {
		  formData.append('avatar', this.imagePath.target.files[0])
	  }

	  this.service.register(formData).subscribe((response: any) => {
		  console.log(response)
		this.activelink = undefined;
		this.thirdTab = true;
		this.progressvalue = '75%';
		this.submitbtn = false;
		this.text = "Verify bank";

		const httpOptions = {
			headers: new HttpHeaders({
			'Content-Type': response?.documents.fileType
			})
		};
		this.loadingBar.stop()

		this.service.sendKey(response?.documents.url, this.fileListARR[0], httpOptions).subscribe(data => {
			console.log(data)

			const httpOptions = {
			headers: new HttpHeaders({
				'Authorization': this.cookie.get('temp')
			})
			};

			const value = {
				'key': response.documents.key,
				'url': response.documents.url,
				'extension': response.documents.extension,
				'fileName': response.documents.fileName,
			}
			console.log(value);

			this.service.sendURL(value, httpOptions).subscribe(data => {

				this.cookieService.set('bankverify', 'false', 1, '/');
				this.cookieService.set('token', this.cookieService.get('temp'), 1, '/');
				this.socket1.emit('tryReconnect', {
				'token': data.token,
				'owner': 'engineer'
				})
				this.socket2.emit('tryReconnect', {
				'token': data.token,
				'owner': 'engineer'
				})
				setTimeout(()=> {
					this.isLazybutton = true
				},300)
				this.activelink = undefined;
				this.thirdTab = true;
				this.progressvalue = '75%';
				this.submitbtn = false;
				this.text = "Verify bank";
				// $('#exampleModal').modal('hide');
				this.loadingBar.stop();
  
			}, err => {
				console.log(err)
				this.loadingBar.stop()
			});
		})


	  })
  
  
	}
  
	deleteDoc(index) {
	  console.log(index)
	  this.docs.splice(index, 1);
	  this.SelectFileArr.splice(index, 1)
	  this.urls.splice(index, 1)
	  this.fileListARR.splice(index, 1)
	  console.log(this.docs);
	  console.log(this.fileListARR);
	  console.log(this.urls);
	  
	}
  
	opengateway() {

	this.loadingBar.start(0)
  
	  this.service.getPaymentURL().subscribe((data: any) => {
		this.progressvalue = '100%';
	
		this.loadingBar.stop()
		window.open(data.url.url, "_self")
		console.log(data);
  
	  }, err => {
		  console.log(err);
		  
		  this.loadingBar.stop()
	  })
	}

	isButtonDisabled(buttonValue) {
      if( buttonValue == false && this.isButtonClicked == false && this.urls.length != 0 ) {
		  return false
	  }
	  return true
	}

	
	removeValue(e) {
		e.preventDefault()
	}
  
	
    newpasswordView() {
		this.newpasswordType = ! this.newpasswordType
	  }
	  confirmpasswordView() {
		this.confirmpasswordType = ! this.confirmpasswordType
	  }
  
  }