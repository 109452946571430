<div style="background-color: white;min-height: 100vh;">
	<ngx-loading-bar [includeSpinner] = false [color]="'#0066FF'"></ngx-loading-bar>

	<form [formGroup]="myforms" (ngSubmit)="onSubmit()">
		<div class="main-box">
			<div class="container reponsivetab pt-5 pt-md-0">
				<div class="d-flex justify-content-between">
					<div >
						<h3>{{text}} Details</h3>
					</div>
					<div class="laptop-button">
						<div class="btndiv">
							<button *ngIf="submitbtn == true" type="button" class="btn btn-light" (click)="previous()">Previous</button>
							<button *ngIf="nextbtn == true" type="button" class="btn btn-primary" (click)="next()">Next</button>
							<button *ngIf="submitbtn == true" type="submit" [disabled]="isButtonDisabled(myforms.invalid)" class="btn btn-primary">Next</button>
						</div>
					</div>
				</div>
				<div class="progress">
					<div class="progress-bar" role="progressbar" [style.width]="progressvalue" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
				</div>
			</div>
			<div class="container">
				<div class="tab-content" id="myTabContent" style="    margin-top: 40px;">
					<div class="tab-pane fade reponsivetab1" [ngClass]="activelink == true ? 'show active' : ''">
						<div class="row">
							<div class="col-lg-5">
								<div class="avatar-upload">
									<div class="avatar-edit">
										<input type='file' id="imageUpload" (change)="readURL($event)" accept=".jpg,.png,.gif,.jpeg" />
										<label for="imageUpload">
											<img src="../../../../assets/icons/edit.svg" alt="">
										</label>
									</div>
									<div class="avatar-preview">
										<div id="imagePreview" [ngStyle]="styles"></div>
									</div>
								</div>
							</div>
							<div class="col-lg-5">
								<div class="form-group">
									<label for="bankname proHireSubHeading" style="font-weight: 700;">First Name</label><span style="color: #F6566B;">*</span>
									<input type="text" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': f.firstName?.invalid && (f.firstName?.dirty || f.firstName?.touched) }" />
									<div *ngIf="f.firstName?.invalid && (f.firstName?.dirty || f.firstName?.touched)" class="invalid-feedback">
										<div *ngIf="f.firstName.errors.required">First Name is required</div>
									</div>
								</div>
								<div class="form-group">
									<label for="bankname proHireSubHeading" style="font-weight: 700;">Last Name</label><span style="color: #F6566B;">*</span>
									<input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': f.lastName?.invalid && (f.lastName?.dirty || f.lastName?.touched) }" />
									<div *ngIf="f.lastName?.invalid && (f.lastName?.dirty || f.lastName?.touched)" class="invalid-feedback">
										<div *ngIf="f.lastName.errors.required">Last Name is required</div>
									</div>
								</div>
								<div class="form-group">
									<label for="bankname proHireSubHeading" style="font-weight: 700;">Phone Number</label>
									<input type="number" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': f.phone?.invalid && (f.phone?.dirty || f.phone?.touched) }" />
									<div *ngIf="f.phone?.invalid && (f.phone?.dirty || f.phone?.touched)" class="invalid-feedback">
										<!-- <div *ngIf="f.phone.errors.required">Phone Number is required</div> -->
										<div *ngIf="f.phone.errors.phonevalidator">Phone Number should be 10 characters</div>
									</div>
								</div>
								<div class="form-group" style="position: relative;">
									<label for="bankname proHireSubHeading" style="font-weight: 700;">Password</label><span style="color: #F6566B;">*</span>
									<input  [type] = "newpasswordType ? 'password' : 'text'" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': f.password?.invalid && (f.password?.dirty || f.password?.touched) }" />
									<i-feather [name]="newpasswordType ? 'eye' : 'eye-off' " (click) = newpasswordView() style="position: absolute;top: 40px; right: 18px;cursor: pointer; color: #9f9f9f;width: 20px;z-index: 3;"></i-feather>
									<div *ngIf="f.password?.invalid && (f.password?.dirty || f.password?.touched)" class="invalid-feedback">
										<div *ngIf="f.password.errors.required">Password is required</div>
										<div *ngIf="f.password.errors.pattern">Password must contain minimum 8 characters, a capital letter, a lowercase letter, a number and a special character.</div>
									</div>
								</div>
								<div class="form-group" style="position: relative;">
									<label for="bankname proHireSubHeading" style="font-weight: 700;">Confirm password</label><span style="color: #F6566B;">*</span>
									<input [type] = "confirmpasswordType ? 'password' : 'text'"  class="form-control" formControlName="confirm_password" [ngClass]="{ 'is-invalid':f.confirm_password?.invalid && (f.confirm_password?.dirty || f.confirm_password?.touched)}" />
									<i-feather [name]="confirmpasswordType ? 'eye' : 'eye-off' " (click) = confirmpasswordView() style="position: absolute;top: 40px; right: 18px;cursor: pointer; color: #9f9f9f;width: 20px;z-index: 3;"></i-feather>         

									<div *ngIf="f.confirm_password?.invalid && (f.confirm_password?.dirty || f.confirm_password?.touched)" class="invalid-feedback">
										<div *ngIf="f.confirm_password.errors.required">Confirm password is required</div>
										<div *ngIf="f.confirm_password.errors.confirmedValidator">Password and Confirm Password must be match.</div>
									</div>
								</div>
								<div class="form-group">
									<label for="bankname proHireSubHeading" style="font-weight: 700;">About me</label><span style="color: #F6566B;">*</span>
									<textarea class="form-control" rows="3" class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': f.description?.invalid && (f.description?.dirty || f.description?.touched) }"></textarea>
									<div *ngIf="f.description?.invalid && (f.description?.dirty || f.description?.touched)" class="invalid-feedback">
										<div *ngIf="f.description.errors.required">About me is required</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade reponsivetab2" [ngClass]="activelink == false ? 'show active' : ''" id="profile" role="tabpanel" aria-labelledby="profile-tab">
						<div class="inputs ">
							<div class="row" *ngFor="let singlelicense of getLocationArr(); let i = index;">
								<div class="col-12 col-md-5">
									<div class="row">
										<div class="col-12">
											<div class="form-group" formArrayName="license">
												<label for="bankname proHireSubHeading" style="font-weight: 700;">License</label><span style="color: #F6566B;">*</span>
												<select class="form-control" id="license" class="form-control" formControlName="{{i}}" [ngClass]="{ 'is-invalid': myforms.get('license')?.controls[i].invalid && ( myforms.get('license')?.controls[i].dirty ||myforms.get('license')?.controls[i].touched )}">
													<option value="Agricultural and Biological Engineering">Agricultural and Biological Engineering</option>
													<option value="Architectural Engineering">Architectural Engineering</option>
													<option value="Chemical">Chemical</option>
													<option value="Civil">Civil</option>
													<option value="Control Systems">Control Systems</option>
													<option value="Electrical">Electrical</option>
													<option value="Environmental">Environmental</option>
													<option value="Fire Protection">Fire Protection</option>
													<option value="Industrial and Systems">Industrial and Systems</option>
													<option value="Mechanical">Mechanical</option>
													<option value="Metallurgical and Materials">Metallurgical and Materials</option>
													<option value="Mining and Mineral Processing">Mining and Mineral Processing</option>
													<option value="Naval Architecture and Marine">Naval Architecture and Marine</option>
													<option value="Nuclear">Nuclear</option>
													<option value="Petroleum">Petroleum</option>
													<option value="Structural">Structural</option>
												</select>
												<div *ngIf=" myforms.get('license')?.controls[i].invalid && ( myforms.get('license')?.controls[i].dirty ||myforms.get('license')?.controls[i].touched )" class="invalid-feedback">
													<div *ngIf="myforms.get('license')?.controls[i].errors.required">Licsense is required</div>
												</div>
											</div>
										</div>
										
									</div>
									<div class="row">
										<div class="col-12">
											<div class="form-group" formArrayName="licenseNumber">
												<label  style="font-weight: 700;">License Number</label><span style="color: #F6566B;">*</span>
												<input type="text" class="form-control" formControlName="{{i}}" [ngClass]="{'is-invalid':  myforms.get('licenseNumber')?.controls[i].invalid && ( myforms.get('licenseNumber')?.controls[i].dirty ||myforms.get('licenseNumber')?.controls[i].touched )}" />
												<div *ngIf="myforms.get('licenseNumber')?.controls[i].invalid && ( myforms.get('licenseNumber')?.controls[i].dirty ||myforms.get('licenseNumber')?.controls[i].touched )" class="invalid-feedback">
													<div *ngIf=" myforms.get('licenseNumber')?.controls[i].errors">License number is required</div>
												</div>
											</div>
											
										</div>

									</div>
								</div>
								<div class="col-12 col-md-7">
								
									<div class="row">
										<div class="col-12 col-md-8">
											<div class="form-group" formArrayName="location">
												<label for="bankname proHireSubHeading" style="font-weight: 700;">Licensed states</label><span style="color: #F6566B;">*</span>
												
                                                <select class="form-control " formControlName="{{i}}" [ngClass]="{'is-invalid':  myforms.get('location')?.controls[i].invalid && ( myforms.get('location')?.controls[i].dirty ||myforms.get('location')?.controls[i].touched )}" >
                                                    <option  [value]="state" *ngFor="let state of states">{{state}}</option>
												</select>
												<div *ngIf="myforms.get('location')?.controls[i].invalid && ( myforms.get('location')?.controls[i].dirty ||myforms.get('location')?.controls[i].touched )" class="invalid-feedback">
													<div *ngIf=" myforms.get('location')?.controls[i].errors">Location number is required</div>
												</div> 
											</div>
										</div>
										<div class="col-12 col-md-8">
											<div class="form-group" formArrayName="expDate">
												<label  style="font-weight: 700;">Expiry Date</label><span style="color: #F6566B;">*</span>
												<input type="date" class="form-control" [min] = "past_dates" (keydown) = "removeValue($event)"  formControlName="{{i}}" [ngClass]="{'is-invalid':  myforms.get('expiryDate')?.controls[i].invalid && ( myforms.get('expiryDate')?.controls[i].dirty ||myforms.get('expiryDate')?.controls[i].touched )}" />
												<div *ngIf="myforms.get('expDate')?.controls[i].invalid && ( myforms.get('expiryDate')?.controls[i].dirty ||myforms.get('expiryDate')?.controls[i].touched )" class="invalid-feedback">
													<div *ngIf=" myforms.get('expDate')?.controls[i].errors">Location number is required</div>
												</div>
											</div>
											
										</div>
										<div class="col-2">
											<div class="deleteButton" *ngIf="getLocationArr().length != 1" (click)=" deleteFeild(i)">
												<img src="../../../../assets/other/minus.svg" alt="">
											</div>
										</div>
									</div>
									
								</div>
								
							</div>
							<div class="row">
								<div class="col-4 col-md-2">
									<div class="addButton" (click)="addInput()">
										<img src="../../../../assets/other/add.svg" alt="">
									</div>
								</div>
							</div>
							<label for="bankname proHireSubHeading" class="mt-3" style="font-weight: 700;">Professional Liability Insurance Document </label><span style="color: #F6566B;">*</span>

							<div style="display: flex;" class="mt-2">

								<div class="uploads">
									<div class="select preview" *ngIf="urls.length  == 0" title="Click here to add file(s)"> 
										<label for="test" class="filelabel">
											<div class="click"> <img src="../../../../assets/icons/upload.svg" alt=""> Upload</div>
											<input class="form-control" type="file"(change)="detectFiles($event)" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.dwg" 
											formControlName="insuranceDoc" [ngClass]="{ 'is-invalid': f.insuranceDoc?.invalid && (f.insuranceDoc?.dirty || f.insuranceDoc?.touched) }">
										  </label>
									</div>
	
	
									<div *ngFor="let url of urls ; let i = index">
										<div class="preview thumbnail" *ngIf="url.FileType === 'png' ||  url.FileType === 'jpg' || url.FileType === 'jpeg'" style="position: relative;">
											 <i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
											<img [src]="url.FileValue" style="width: 95%;height: 95%;">
											<p>{{url.originalName}}</p>
	
										</div>
		
										<div class="preview thumbnail pdfthumbnail" *ngIf=" url.FileType == 'doc' || url.FileType == 'docx'" style="position: relative;">
											<i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
											<img src="../../../../assets/icons/doc.png"   alt="">
											<p>{{url.originalName}}</p>
										</div>
										<div class="preview thumbnail pdfthumbnail" *ngIf="url.FileType === 'pdf'" style="position: relative;">
											<i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
											<img src="../../../../assets/icons/pdf.png"   >
											<p>{{url.originalName}}</p>
										</div>
										<div class="preview thumbnail pdfthumbnail" *ngIf=" url.FileType == 'dwg'" style="position: relative;">
											<i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
											<img src="../../../../assets/icons/dwg.png"   >
											<p>{{url.originalName}}</p>
										</div>
										<div class="preview thumbnail pdfthumbnail" *ngIf=" url.FileType == 'rar' || url.FileType == 'zip'" style="position: relative;">
											<i class="icon-close" (click) = "deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
											<img src="../../../../assets/icons/zip.png"   >
											<p>{{url.originalName}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [ngClass]="thirdTab == true ? 'show active' : ''" id="contact" role="tabpanel" aria-labelledby="contact-tab">
						<div class="bankDetail">
							<p>Add your card/bank account and verify your account details. You will be redirected to the payment gateway.</p>
							<button type="button" [disabled] = "!isLazybutton" class="btn  btn-block" (click)="opengateway()">Click to verify</button>
						</div>
					</div>
				</div>
				<div class="btndiv mobile-btns">
					<button *ngIf="submitbtn == true" type="button" class="btn btn-light" (click)="previous()">Previous</button>
					<button *ngIf="nextbtn == true" type="button" class="btn btn-primary" (click)="next()">Next</button>
					<button *ngIf="submitbtn == true" type="submit" [disabled]="isButtonDisabled(myforms.invalid)" class="btn btn-primary">Next</button>
				</div>
			</div>
			<div></div>
		</div>
	</form>
</div>
<div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="exampleModal">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content loadingmodal">
			<div class="modal-body">
				<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
				</div> <span class="loadText">Loading please wait...</span>
			</div>
		</div>
	</div>
</div>