import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'onboard', pathMatch: 'full' },
  { path: 'onboard', loadChildren: () => import('./component/onboard/onboard.module').then(m => m.OnboardModule) },
   { path: 'dashboard', loadChildren: () => import('./component/dashboard/dashboard.module').then(m => m.DashboardModule) },
   { path: '**', redirectTo: 'onboard', pathMatch: 'full' },  // Wildcard route for a 404 page

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy:PreloadAllModules,
    scrollPositionRestoration: 'enabled',onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
