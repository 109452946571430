import {  AuthServiceService } from './service/auth/auth-service.service';
import { ProjectService } from './service/project/project.service';
import { AuthInterceptor } from './service/authinterceptor/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnboardModule } from './component/onboard/onboard.module';
import { DashboardModule } from './component/dashboard/dashboard.module';
// import { HeaderComponent } from './module/header/header.component';

import { HttpClientModule,HTTP_INTERCEPTORS  } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgPipesModule, ReversePipe } from 'ngx-pipes';
import { SocketoneService, SockettwoService } from './service/socketconnection.service';
import { FooterComponent } from './component/footer/footer.component';
import { SharedmoduleModule } from './module/sharedmodule.module';


// const config: SocketIoConfig = { url: "https://illuminedev.herokuapp.com/", options: {} };

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
   
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OnboardModule,
    DashboardModule,
    SocketIoModule,
    GooglePlaceModule,
    HttpClientModule,
    SharedmoduleModule,

    NgPipesModule
  ],
  providers: [AuthServiceService,ReversePipe,CookieService,
    SocketoneService,SockettwoService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass : AuthInterceptor,
      multi : true
    },
    ProjectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
