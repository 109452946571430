import { AbstractControl } from '@angular/forms';

export function ValidatePhone(control: AbstractControl) {
    if (control.value != null && control.value !== '') {
     
        if (control.value.toString().length == 10) {
          return null;
        } else {
          return {
            phonevalidator: { valid: false }
          };
        }
      } else {
        return null;
      }
}