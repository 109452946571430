import { ProjectguardGuard } from './../../guard/projectguard.guard';
import { RegisterGuardGuard } from './../../guard/register-guard.guard';
import { AuthGuard } from './../../guard/auth.guard';
import { SuccessmailComponent } from './successmail/successmail.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { SignupComponent } from './signup/signup.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OnboardComponent } from './onboard.component';
import { EmailverifyGuard } from 'src/app/guard/emailverify.guard';
import { ResendemailGuard } from 'src/app/guard/resendemail/resendemail.guard';

const routes: Routes = [
  {
    path: '', children: [
      { path: '', redirectTo: 'signup', pathMatch: 'full' },
      {
        path: 'signup', component:SignupComponent,  canActivate: [AuthGuard]
      },
      {
        path:'emailverify', component:VerifyemailComponent, canActivate: [ResendemailGuard]
      },
      {
        path:'emailConfirmation/:id', component:SuccessmailComponent,canActivate: [EmailverifyGuard]
      },
      {
        path:'register', component:RegisterComponent,canActivate: [RegisterGuardGuard]
      },
      {
        path:'login', component:LoginComponent,canActivate: [AuthGuard]
      },
      {
        path:'profile', component:ViewprofileComponent,canActivate: [ProjectguardGuard]
      },
      {
        path:'forgotpassword/:id', component:ForgetpasswordComponent,canActivate: [AuthGuard]
      },
      { path: '**', redirectTo: 'signup', pathMatch: 'full' },  // Wildcard route for a 404 page


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardRoutingModule { }
