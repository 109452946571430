import { RegisterGuardGuard } from './../guard/register-guard.guard';
import { ProjectguardGuard } from './../guard/projectguard.guard';
import { AuthGuard } from './../guard/auth.guard';
import { SafePipe } from './../pipes/safe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmptystatesComponent } from './../component/extra/emptystates/emptystates.component';
import { LoadingComponent } from './../component/extra/loading/loading.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgPipesModule} from 'ngx-pipes';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { NotificationemptyComponent } from '../component/extra/notificationempty/notificationempty.component';


@NgModule({
  declarations: [
   LoadingComponent,
   EmptystatesComponent,
   HeaderComponent,
   SafePipe,
   NotificationemptyComponent
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
    
  ],
  exports: [
    LoadingComponent,
    EmptystatesComponent,
    SafePipe,
    HeaderComponent,
    NotificationemptyComponent   
 
  ],
  providers: [AuthGuard, ProjectguardGuard,RegisterGuardGuard]
})
export class SharedmoduleModule { }
