<ngx-loading-bar [includeSpinner]=false [color]="'#0066FF'"></ngx-loading-bar>
<!-- <app-header></app-header> -->
<main *ngIf="loading == false; else Loading" class="mb-5 pb-5">
	<div class="container">
		<div class="box">
			<div class="row">
				<div class="col-md-3 mb-4 mb-md-0">
					<div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"> <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Profile</a>  <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Billing</a>
						<a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Reviews</a>
					</div>
				</div>
				<div class="col-md-9">
					<div class="tab-content" style="    max-height: 77vh;overflow: auto;" id="v-pills-tabContent">
						<div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
							<form [formGroup]="myforms" (ngSubmit)="onSubmit()">
								<div class="header flex-wrap flex-md-nowrap">
									<div class="header-text">
										<h4>Your Profile</h4>
										<p>You can modify your personal information here.</p>
									</div>
									<div class="saveBtn mb-3 mb-md-0">
										<button *ngIf="!editDetail" class="btn mr-2" style="background-color: #f6566b;" (click)="editDetail = true">Cancel</button>
										<button *ngIf="!editDetail" type="submit" class="btn" [disabled]="myforms.invalid">Save</button>
										<button *ngIf="editDetail" class="btn " style="background-color: #f6566b;" (click)="startEdit() ">Edit</button>
									</div>
								</div>
								<div class="avatar-upload my-4">
									<div class="avatar-edit">
										<input type='file' id="imageUpload" (change)="readURL($event)" accept=".png,.jpg,.jpeg" />
										<label for="imageUpload" *ngIf="!editDetail">
											<img src="../../../../assets/icons/edit.svg" alt="">
										</label>
									</div>
									<div>
										<img [src]="this.imgURL" alt="" class="avatar-preview">
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12">
										<div class="form-group">
											<label for="bankname proHireSubHeading" style="font-weight: 700;">About me</label><span style="color: #F6566B;">*</span>
											<textarea class="form-control" rows="3" [readonly]="editDetail" class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': f.description?.invalid && (f.description?.dirty || f.description?.touched) }"></textarea>
											<div *ngIf="f.description?.invalid && (f.description?.dirty || f.description?.touched)" class="invalid-feedback">
												<div *ngIf="f.description.errors.required">About me is required</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-5">
										<div class="form-group">
											<label style="font-weight: 700;">Password</label><span style="color: #F6566B;font-weight: 600;">*</span>
											<div class="input-group mb-3">
												<input type="password" readonly style="cursor: not-allowed;" class="form-control remove-outline" value="password#82" aria-label="Recipient's username" aria-describedby="basic-addon2">
												<div class="input-group-append"> <span class="input-group-text" style="background: #707070;
												  color: white;cursor: pointer;" id="basic-addon2" data-toggle="modal" data-target="#passwordModal">Change</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-5">
										<div class="form-group">
											<label for="bankname proHireSubHeading" style="font-weight: 700;">First Name</label><span style="color: #F6566B;">*</span>
											<input type="text" class="form-control" [readonly]="editDetail" formControlName="firstName" [ngClass]="{ 'is-invalid': f.firstName?.invalid && (f.firstName?.dirty || f.firstName?.touched) }" />
											<div *ngIf="f.firstName?.invalid && (f.firstName?.dirty || f.firstName?.touched)" class="invalid-feedback">
												<div *ngIf="f.firstName.errors.required">First Name is required</div>
												<!-- <div *ngIf="f.firstName.errors.minlength">Username must be at least 6 characters</div> -->
											</div>
										</div>

									</div>
									<div class="col-lg-5">
										<div class="form-group">
											<label for="bankname proHireSubHeading" style="font-weight: 700;">Last Name</label><span style="color: #F6566B;">*</span>
											<input type="text" class="form-control" [readonly]="editDetail" formControlName="lastName" [ngClass]="{ 'is-invalid': f.lastName?.invalid && (f.lastName?.dirty || f.lastName?.touched) }" />
											<div *ngIf="f.lastName?.invalid && (f.lastName?.dirty || f.lastName?.touched)" class="invalid-feedback">
												<div *ngIf="f.lastName.errors.required">Last Name is required</div>
												<!-- <div *ngIf="f.firstName.errors.minlength">Username must be at least 6 characters</div> -->
											</div>
										</div>
									</div>
									<div class="col-lg-5">
										<div class="form-group">
											<label style="font-weight: 700;">Email ID</label><span style="color : #f6566b;font-weight: 700;"> (Can't be changed)</span>
											<input style="cursor: not-allowed;" type="text" [value]="response?.profile.email" class="form-control remove-outline" name="email" readonly/>
										</div>
									</div>
									<div class="col-lg-5">
										<div class="form-group">
											<label for="bankname proHireSubHeading" style="font-weight: 700;">Phone Number</label>
											<input type="number" class="form-control" [readonly]="editDetail"  formControlName="phone" [ngClass]="{ 'is-invalid': f.phone?.invalid && (f.phone?.dirty || f.phone?.touched) }" />
											<div *ngIf="f.phone?.invalid && (f.phone?.dirty || f.phone?.touched)" class="invalid-feedback">
												<!-- <div *ngIf="f.phone.errors.required">Phone Number is required</div> -->
												<div *ngIf=" f.phone.errors.phonevalidator">Phone Number must be 10 characters</div>

											</div>
										</div>
									</div>
								</div>
								<hr>
								<ng-container *ngIf="editDetail">
									<div *ngFor="let singlelicense of dummyData; let i = index;">
										<div class="row">
											<div class="col-lg-5">
												<div class="form-group">
													<label for="bankname proHireSubHeading" style="font-weight: 700;">License</label><span style="color: #F6566B;">*</span>
													<input type="text" class="form-control" [value]="singlelicense.licence_type" [disabled]=true/>
												</div>
											</div>
											<div class="col-lg-5">
												<div class="form-group">
													<label for="bankname proHireSubHeading" style="font-weight: 700;">Licensed State</label><span style="color: #F6566B;">*</span>
													<input type="text" class="form-control" [value]="singlelicense.state" [disabled]=true/>
												</div>
											</div>
											<div class="col-lg-5">
												<div class="form-group">
													<label for="bankname proHireSubHeading" style="font-weight: 700;">License Number</label><span style="color: #F6566B;">*</span>
													<input type="text" class="form-control" [value]="singlelicense.licence_num" [disabled]=true/>
												</div>
											</div>
											<div class="col-lg-5">
												<div class="form-group">
													<label for="bankname proHireSubHeading" style="font-weight: 700;">Expiry Date</label><span style="color: #F6566B;">*</span>
													<input type="text" class="form-control" [value]="singlelicense.expDate | date:'shortDate' : '-0800'" [disabled]=true   />
												</div>
											</div>
										</div>
										<hr>
									</div>
								</ng-container>
								<ng-container *ngIf="!editDetail">
									<div *ngFor="let singlelicense of getLocationArr(); let i = index;">
										<div class="row">
											<div class="col-md-5">
												<div class="row">
													<div class="col-12">
														<div class="form-group" formArrayName="license">
															<label for="bankname proHireSubHeading" style="font-weight: 700;">License</label><span style="color: #F6566B;">*</span>
															<select class="form-control" class="form-control" formControlName="{{i}}" [ngClass]="{ 'is-invalid': myforms.get('license')?.controls[i].invalid && ( myforms.get('license')?.controls[i].dirty ||myforms.get('license')?.controls[i].touched )}">
																<option [disabled]="editDetail" value="Agricultural and Biological Engineering">Agricultural and Biological Engineering</option>
																<option [disabled]="editDetail" value="Architectural Engineering">Architectural Engineering</option>
																<option [disabled]="editDetail" value="Chemical">Chemical</option>
																<option [disabled]="editDetail" value="Civil">Civil</option>
																<option [disabled]="editDetail" value="Control Systems">Control Systems</option>
																<option [disabled]="editDetail" value="Electrical">Electrical</option>
																<option [disabled]="editDetail" value="Environmental">Environmental</option>
																<option [disabled]="editDetail" value="Fire Protection">Fire Protection</option>
																<option [disabled]="editDetail" value="Industrial and Systems">Industrial and Systems</option>
																<option [disabled]="editDetail" value="Mechanical">Mechanical</option>
																<option [disabled]="editDetail" value="Metallurgical and Materials">Metallurgical and Materials</option>
																<option [disabled]="editDetail" value="Mining and Mineral Processing">Mining and Mineral Processing</option>
																<option [disabled]="editDetail" value="Naval Architecture and Marine">Naval Architecture and Marine</option>
																<option [disabled]="editDetail" value="Nuclear">Nuclear</option>
																<option [disabled]="editDetail" value="Petroleum">Petroleum</option>
																<option [disabled]="editDetail" value="Structural">Structural</option>
															</select>
															<div *ngIf=" myforms.get('license')?.controls[i].invalid && ( myforms.get('license')?.controls[i].dirty ||myforms.get('license')?.controls[i].touched )" class="invalid-feedback">
																<div *ngIf="myforms.get('license')?.controls[i].errors.required">Licsense is required</div>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<div class="form-group" formArrayName="licenseNumber">
															<label style="font-weight: 700;">License Number</label><span style="color: #F6566B;">*</span>
															<input type="text" [readonly]="editDetail" class="form-control" formControlName="{{i}}" [ngClass]="{'is-invalid':  myforms.get('licenseNumber')?.controls[i].invalid && ( myforms.get('licenseNumber')?.controls[i].dirty ||myforms.get('licenseNumber')?.controls[i].touched )}" />
															<div *ngIf="myforms.get('licenseNumber')?.controls[i].invalid && ( myforms.get('licenseNumber')?.controls[i].dirty ||myforms.get('licenseNumber')?.controls[i].touched )" class="invalid-feedback">
																<div *ngIf=" myforms.get('licenseNumber')?.controls[i].errors">License number is required</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-7">
												<div class="row">
													<div class="col-8">
														<div class="form-group" formArrayName="location">
															<label for="bankname proHireSubHeading" style="font-weight: 700;">Licensed states</label><span style="color: #F6566B;">*</span>
															<select class="form-control " formControlName="{{i}}" [ngClass]="{'is-invalid':  myforms.get('location')?.controls[i].invalid && ( myforms.get('location')?.controls[i].dirty ||myforms.get('location')?.controls[i].touched )}">
																<option [value]="state" [disabled]="editDetail" *ngFor="let state of states">{{state}}</option>
															</select>
															<div *ngIf="myforms.get('location')?.controls[i].invalid && ( myforms.get('location')?.controls[i].dirty ||myforms.get('location')?.controls[i].touched )" class="invalid-feedback">
																<div *ngIf=" myforms.get('location')?.controls[i].errors">Location number is required</div>
															</div>
														</div>
													</div>
													<div class="col-8">
														<div class="form-group" formArrayName="expDate">
															<label style="font-weight: 700;">Expiry Date</label><span style="color: #F6566B;">*</span>
															<input type="date" [readonly]="editDetail" [min]="past_dates" (keydown) = "removeValue($event)" class="form-control" formControlName="{{i}}" [ngClass]="{'is-invalid':  myforms.get('expDate')?.controls[i].invalid && ( myforms.get('expDate')?.controls[i].dirty ||myforms.get('expDate')?.controls[i].touched )}" />
															<div *ngIf="myforms.get('expDate')?.controls[i].invalid && ( myforms.get('expDate')?.controls[i].dirty ||myforms.get('expDate')?.controls[i].touched )" class="invalid-feedback">
																<div *ngIf=" myforms.get('expDate')?.controls[i].errors">Expiry date is required</div>
															</div>
														</div>
													</div>
													<div class="col-2">
														<div class="deleteButton" *ngIf="getLocationArr().length != 1" (click)=" deleteFeild(i)">
															<img src="../../../../assets/other/minus.svg" alt="" *ngIf="!editDetail">
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr>
									</div>
									<div class="row" *ngIf="!editDetail">
										<div class="col-4 col-md-2">
											<div class="addButton" (click)="addInput()">
												<img src="../../../../assets/other/add.svg" alt="">
											</div>
										</div>
									</div>
								</ng-container>
								<div class="row">
									<div class="col-12">
										<label for="bankname proHireSubHeading" style="font-weight: 700;">Professional Liability Insurance Document</label>
										<div style="display: flex;" class="mt-3">
											<div class="uploads" *ngIf="!editDetail">
												<div class="select preview" *ngIf="urls.length  == 0" title="Click here to add file(s)">
													<label for="test" class="filelabel">
														<div class="click">
															<img src="../../../../assets/icons/upload.svg" alt="">Upload</div>
														<input class="form-control" type="file" (change)="detectFiles($event)" accept="image/jpeg,image/png,application/pdf,.doc,.docx,.dwg,.zip,.rar" formControlName="insuranceDoc" [ngClass]="{ 'is-invalid': f.insuranceDoc?.invalid && (f.insuranceDoc?.dirty || f.insuranceDoc?.touched) }">
													</label>
												</div>
												<div *ngFor="let url of urls ; let i = index">
													<div class="preview thumbnail" *ngIf="url.FileType === 'png' ||  url.FileType === 'jpg' || url.FileType === 'jpeg'" style="position: relative;"> <i class="icon-close" (click)="deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
														<img [src]="url.FileValue" style="width: 95%;height: 95%;">
														<!-- <p>{{url.Filename || ''}}</p> -->
													</div>
													<div class="preview thumbnail pdfthumbnail" *ngIf=" url.FileType == 'doc' || url.FileType == 'docx'" style="position: relative;">	<i class="icon-close" (click)="deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
														<img src="../../../../assets/icons/doc.png" alt="">
														<!-- <p>{{url.Filename || ''}}</p> -->
													</div>
													<div class="preview thumbnail pdfthumbnail" *ngIf="url.FileType === 'pdf'" style="position: relative;">	<i class="icon-close" (click)="deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
														<img src="../../../../assets/icons/pdf.png">
														<!-- <p>{{url.Filename || ''}}</p> -->
													</div>
													<div class="preview thumbnail pdfthumbnail" *ngIf=" url.FileType == 'dwg'" style="position: relative;">	<i class="icon-close" (click)="deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
														<img src="../../../../assets/icons/dwg.png">
														<!-- <p>{{url.Filename || ''}}</p> -->
													</div>
													<div class="preview thumbnail pdfthumbnail" *ngIf=" url.FileType == 'zip' || url.FileType == 'rar'" style="position: relative;">	<i class="icon-close" (click)="deleteDoc(i)"><img src="../../../../assets/icons/close.svg" alt=""></i>
														<img src="../../../../assets/icons/zip.png">
														<!-- <p>{{url.Filename || ''}}</p> -->
													</div>
												</div>
											</div>
											<div class=" d-block" *ngIf="editDetail">
												<a href="{{response.docs}} " download class="preview thumbnail mb-2" *ngIf=" response.file === 'png' ||   response.file === 'jpg' ||  response.file === 'jpeg'" style="position: relative;">
													<img [src]="response.docs" style="width: 95%; height: 95%;">
													<!-- <p>{{url.Filename || ''}}</p> -->
												</a>
												<a href="{{response.docs}} " download class="preview thumbnail pdfthumbnail mb-2" *ngIf=" response.file == 'doc' || response.file == 'docx'" style="position: relative;">
													<img src="../../../../assets/icons/doc.png " alt="">
													<!-- <p>{{url.Filename || ''}}</p> -->
												</a>
												<a href="{{response.docs}} " download class="preview thumbnail pdfthumbnail mb-2" *ngIf="response.file === 'pdf'" style="position: relative;">
													<img src="../../../../assets/icons/pdf.png ">
													<!-- <p>{{url.Filename || ''}}</p> -->
												</a>
												<a href="{{response.docs}} " download class="preview thumbnail pdfthumbnail mb-2" *ngIf="response.file == 'dwg'" style="position: relative;">
													<img src="../../../../assets/icons/dwg.png">
													<!-- <p>{{url.Filename || ''}}</p> -->
												</a>
												<a href="{{response.docs}} " download class="preview thumbnail pdfthumbnail mb-2" *ngIf="response.file == 'zip' || response.file == 'rar'" style="position: relative;">
													<img src="../../../../assets/icons/zip.png">
													<!-- <p>{{url.Filename || ''}}</p> -->
												</a>Insurance.{{response.file}}</div>
										</div>
									</div>
								</div>
								<div style="display: flex;justify-content: flex-end;">
									<div class="saveBtn mb-3 mb-md-0">
										<button *ngIf="!editDetail" class="btn mr-2" style="background-color: #f6566b;
										font-weight: 700;
										color: white;" (click)="editDetail = true">Cancel</button>
										<button *ngIf="!editDetail" type="submit" class="btn" [disabled]="myforms.invalid" style="background-color: #0066ff;
										font-weight: 700;
										color: white;">Save</button>
									</div>
								</div>
							</form>
						</div>
						<div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
							<div class="header flex-wrap flex-md-nowrap">
								<div class="header-text">
									<h4>Your Transactions</h4>
									<p>You can see your bank and transaction information here.</p>
								</div>
								<div class="mb-3 mb-md-0"> <a class="btn " [href]="response.link.url" role="button" style="
                                    color: white;
                                    background: #007bff;
                                    font-weight: 600;
                                ">Billing Dashboard</a> 
								</div>
							</div>
							<div style="overflow-x: auto;">
								<table class="table" *ngIf="response.data.length != 0; else  emptyTransaction">
									<thead>
										<tr>
											<th scope="col">Date</th>
											<th scope="col">Project Name</th>
											<th scope="col">Client Name</th>
											<th scope="col">Amount ($)</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let data of response.data">
											<td>{{data.updatedAt | date:'short' : '-0800'}}</td>
											<td>{{data.projectID.projectName}}</td>
											<td>{{data.clientID.username}}</td>
											<td>{{data.amount}}</td>
										</tr>
									</tbody>
								</table>
								<ng-template #emptyTransaction>
									<div class="mt-4" style="height: 50vh;">
										<div class="verified ">
											<div style="text-align: center;">
												<div class="state-container">
													<img src="../../../../assets/other/empty.svg" alt="">
													<p style="margin-top: 20px;">No transaction history</p>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</div>
						</div>
						<div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
							<div class="header">
								<div class="header-text">
									<h4>Client's feedback</h4>
									<p>Have a focus on self improvement.</p>
								</div>
								<div></div>
							</div>
							<div style="overflow-x: auto;">
								<table class="table table-borderless" *ngIf="response.feedback.feedbacks.length != 0; else emptyTransaction">
									<thead>
										<!-- <tr>
										  <th scope="col">Customers</th>
										  <th scope="col">Review</th>
									   </tr> -->
									</thead>
									<tbody>
										<ng-container *ngFor="let feedback of response.feedback.feedbacks">
											<tr>
												<td style="width: 43%">
													<h5 style="font-weight: 600;">{{feedback.clientID.username}}</h5>
													<!-- <img src="../../assets/icons/map.svg" alt=""> {{review?.clientID.username}} <span style=" margin: 5px; font-size: 14px;font-weight: 600; ">Chennai</span> -->
													<p style="font-size: 14px;" routerLink="/dashboard/completedprojects/{{feedback.projectID._id}}">{{feedback.projectID.projectName}}</p>
												</td>
												<td>
													<h5>{{feedback.rating}}<span><img src="../../../../assets/images/star.svg" alt="" style=" width: 15px; margin-left: 6px; position: relative;bottom: 2px;"></span></h5>
													{{feedback.feedback}}
													<p class="mt-2" style="    font-size: 14px;
												   font-weight: 600;">{{feedback.date | date}}</p>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
								<ng-template #emptyTransaction>
									<div style="height: 50vh;">
										<div class="verified ">
											<div class="mt-4" style="text-align: center;">
												<div class="state-container">
													<img src="../../../../assets/other/empty.svg" alt="">
													<p style="margin-top: 20px;">No Feedback</p>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
<ng-template #Loading>
	<div style="display: flex;align-items: center;justify-content: center;height: 100vh;">
		<div class="row justify-content-center pt-4 ">
			<div class="d-flex ">
				<div class="spinner-border " role="status "> <span class="sr-only ">Loading...</span> 
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- loading modal -->
<div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="exampleModal">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content loadingmodal">
			<div class="modal-body">
				<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> 
				</div> <span class="loadText">Loading please wait...</span> 
			</div>
		</div>
	</div>
</div>
<!-- password modal -->
<div class="modal fade" id="passwordModal" tabindex="-1" role="dialog">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<form name="form" (ngSubmit)="fd.form.valid && resetpass() " #fd="ngForm" [mustMatch]="['password', 'confirmPassword']" novalidate>
				<div class="modal-header" style="border:none;">
					<h5 class="modal-title" id="exampleModalLabel" style="font-weight: 600;">Change Password</h5>
				</div>
				<div class="modal-body" style="padding: 2rem">
					<!-- <p class=" card-text mb-auto">It's our mission to help you find the next big project.</p> -->
					<div class="form-group">
						<label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Old password</label>
						<input type="password" autocomplete="off" type="password" class="form-control" name="oldPassword" [(ngModel)]="modelPass.oldPassword" #oldPassword="ngModel" [ngClass]="{ 'is-invalid':oldPassword.invalid && (oldPassword.dirty || oldPassword.touched) }" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~@$!%*#.?&])[A-Za-z\d`~@$!%#.*?&]{8,}$" style="padding-top: 15px; padding-bottom: 15px;">
						<div *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)" class="invalid-feedback">
							<div *ngIf="oldPassword.errors.required">Old password is required</div>
							<div *ngIf="oldPassword.errors.minlength">Old password must be at least 6 characters</div>
							<div *ngIf="oldPassword.errors.pattern">Password must contain minimum 8 characters, a capital letter, a lowercase letter, a number and a special character.</div>
						</div>
					</div>
					<div class="form-group">
						<label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">New password</label>
						<input type="password" autocomplete="off" type="password" class="form-control" name="password" [(ngModel)]="modelPass.password" #password="ngModel" [ngClass]="{ 'is-invalid':password.invalid && (password.dirty || password.touched) }" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~@$!%*#.?&])[A-Za-z\d`~@$!%#.*?&]{8,}$" style="padding-top: 15px; padding-bottom: 15px;">
						<div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
							<div *ngIf="password.errors.required">Password is required</div>
							<div *ngIf="password.errors.minlength">Password must be at least 8 characters</div>
							<div *ngIf="password.errors?.pattern">Password must contain minimum 8 characters, a capital letter, a lowercase letter, a number and a special character.</div>
						</div>
					</div>
					<div class="form-group">
						<label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Re-enter password</label>
						<input type="password" autocomplete="off" type="password" class="form-control" name="confirmPassword" [(ngModel)]="modelPass.confirmPassword" #confirmPassword="ngModel" [ngClass]="{ 'is-invalid': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) }" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#.?&])[A-Za-z\d@$!%#.*?&]{8,}$" style="padding-top: 15px; padding-bottom: 15px;">
						<div *ngIf="{ 'is-invalid': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) }" class="invalid-feedback">
							<div *ngIf="confirmPassword.errors?.required">Confirm Password is required</div>
							<div *ngIf="confirmPassword.errors?.mustMatch">Passwords must match</div>
							<div *ngIf="confirmPassword.errors?.pattern">Password must contain minimum 8 characters, a capital letter, a lowercase letter, a number and a special character.</div>
						</div>
					</div>
				</div>
				<div class="modal-footer" style="border:none;">
					<button type="button" class="btn btn-secondary" style="font-weight: 600;  border-radius: 5px; padding: 4px 16px; " data-dismiss="modal">Close</button>
					<button type="submit" class="btn btn-primary" style="font-weight: 600;  border-radius: 5px; padding: 4px 16px; " [disabled]="fd.form.invalid ">Save changes</button>
				</div>
			</form>
		</div>
	</div>
</div>